import * as Yup from 'yup';

const resumeSchema = Yup.object().shape({
    "firstName": Yup.string()
        .min(5, "should be minimum 5 characters")
        .required('Required'),
    "lastName": Yup.string()
        .min(5, "should be minimum 5 characters")
        .required('Required'),
    "phone": Yup.string().matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }).required('Required'),
    "email": Yup.string().email().required('Required'),
    "connect": Yup.object().shape({
        "userName": Yup.string()
            .min(5, "should be minimum 5 characters")
            .required('Required'),
        "password": Yup.string()
            .min(5, "should be minimum 5 characters")
            .required('Required'),
        "accessCode": Yup.string()
            .min(5, "should be minimum 5 characters")
            .required('Required')
    }),
    "totalYearsOfExperience": Yup.number().required('Required'),
    "yearsOfExperienceInUS": Yup.number().required('Required'),
    "NumberOfProjectsInUS": Yup.number().required('Required'),
    "NumberOfProjectsOutsideUS": Yup.number("Should be a Number").required('Required'),
    "currentLocation": Yup.string().required(),
    // "currentLocation": Yup.object().shape({
    //     "street": Yup.string()
    //         .required('Required'),
    //     "city": Yup.string()
    //         .required('Required'),
    //     "state": Yup.string()
    //         .min(5, "should be minimum 5 characters")
    //         .required('Required'),
    //     "zip": Yup.number("Should be a Number")
    //         .min(5, "should be minimum 5 characters")
    //         .typeError("Invalid Zip Code")
    //         .required('Required'),
    //     "community": Yup.string()
    //         .required('Required')
    // }),
    "visa": Yup.object().shape({
        "currentVisStatus": Yup.string()
            .required('Required'),
        "visaValidUntil": Yup.string()
            .required('Required'),
        "visaHistory": Yup.array().of(Yup.object().shape({
            "isApplicable": Yup.bool(),
            "visa": Yup.string(),
            "startDate": Yup.date().required('Required').typeError("Invalid Date, should be MM/YYYY"),
            "endDate": Yup.date().required('Required').typeError("Invalid Date, should be MM/YYYY"),
        }))
    }),
    "projectsInUS": Yup.array().of(Yup.object().shape({
        endClient: Yup.string().min(3).required(),
        startDate: Yup.object().shape({
            month: Yup.number().min(1).max(12).required(),
            year: Yup.number().min(2000).max(2022).required()
        }),
        workLocation: Yup.object().shape({
            street: Yup.string().required(),
            city: Yup.string().required(),
            state: Yup.string().required(),
            zip: Yup.number().required(),
        }),
        homeLocation: Yup.object().shape({
            street: Yup.string().required(),
            city: Yup.string().required(),
            state: Yup.string().required(),
            zip: Yup.number().required(),
        }),
        companyWebsite: Yup.string().required(),
        aboutProject: Yup.string(),
        trainerNotes: Yup.string(),
        notesAboutProject: Yup.string(),
        VisaDuringThisProject: Yup.string().required()

    }))

    // "currentVisaValidUntil": "",
    // "employerDetails": {
    //     "contactName": Yup.string()
    //         .min(5, "should be minimum 5 characters")
    //         .required('Required'),
    //     "contactEmail": "",
    //     "contactPhone": "",
    //     "address": Yup.string()
    //         .min(5, "should be minimum 5 characters")
    //         .required('Required'),
    //     "companyName": Yup.string()
    //         .min(5, "should be minimum 5 characters")
    //         .required('Required')
    // },

    // "projectsInUS": [
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     }
    // ],
    // "projectsOutsideUS": [
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     },
    //     {
    //         "endClient": "",
    //         "startDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "endDate": {
    //             "month": "",
    //             "year": ""
    //         },
    //         "workLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": ""
    //         },
    //         "homeLocation": {
    //             "street": "",
    //             "city": "",
    //             "state": "",
    //             "zip": "",
    //             "community": ""
    //         },
    //         "companyWebsite": "",
    //         "aboutProject": "",
    //         "trainerNotes": "",
    //         "notesAboutProject": "",
    //         "VisaDuringThisProject": ""
    //     }
    // ],
    // "interview": {
    //     "interviewingClient": {},
    //     "vendorDetails": {},
    //     "mode": "",
    //     "date": "",
    //     "time": "",
    //     "timeZone": "",
    //     "jobDescription": "",
    //     "anythingToFocusOn": "",
    //     "notes": ""
    // }
})


export default resumeSchema