import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  firstName,
  lastName,
  phone,
  email,
  totalExpYears,
  totalExpMonths,
  USTotalExpYears,
  USTotalExpMonths,
  personalSummary,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu } from 'antd';

class PersonalDetailsView extends React.Component {
  getFieldDisplayProps = (id, postText = '') => {
    return {
      value: this.props.values[id] + postText,
      id,
    };
  };

  getExperienceField = (yearsId, monthsId) => {
    return {
      value: `${this.props.values[yearsId]} Years ${this.props.values[monthsId]} Months`,
      id: yearsId,
    };
  };

  render() {
    return (
      <Card
        className="add-shadow"
        title={
          <span className="card-header__label">{'A little about YOU'}</span>
        }
        extra={
          <>
            <Button onClick={this.props.handleEdit}>Edit</Button>
          </>
        }
      >
        <Row gutter={16}>
          <Col md={6}>
            <FieldDisplay {...this.getFieldDisplayProps(firstName)} />
          </Col>
          <Col md={6}>
            <FieldDisplay {...this.getFieldDisplayProps(lastName)} />
          </Col>
          <Col md={6}>
            <FieldDisplay {...this.getFieldDisplayProps(phone)} />
          </Col>
          <Col md={6}>
            <FieldDisplay {...this.getFieldDisplayProps(email)} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={6}>
            <FieldDisplay
              {...this.getExperienceField(
                totalExpYears,
                totalExpMonths,
              )}
            />
          </Col>
          <Col md={6}>
            <FieldDisplay
              {...this.getExperienceField(
                USTotalExpYears,
                USTotalExpMonths,
              )}
            />
          </Col>
        </Row>
        <Row gutter={16}>
        <FieldDisplay
          {...this.getFieldDisplayProps(personalSummary)}
        />
        </Row>
        {/* <FieldDisplay {...this.getFieldDisplayProps(USTotalExpYears)} />
        <FieldDisplay {...this.getFieldDisplayProps(USTotalExpMonths)} /> */}
      </Card>
    );
  }
}
export default withFirebase(PersonalDetailsView);
