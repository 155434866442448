import React from 'react';
import { Field } from 'formik';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Select,
  Radio,
  TimePicker,
} from 'antd';
import Datepicker from '../Datepicker';
import Timepicker from '../TimePicker';
import Dropdown from '../Dropdown';
import Slider from '../Slider';
import Checky from '../Checkbox';
import './FormField.scss';

const FormField = ({
  value,
  id,
  name,
  onChange,
  onBlur,
  label = 'label',
  rows = 1,
  type = 'text',
  placeholder = '',
  ...props
}) => {
  debugger;
  let component = null;
  switch (type) {
    case 'textArea':
      component = Input.TextArea;
      break;
    case 'date':
      component = Datepicker;
      break;
    case 'time':
      component = Timepicker;
      break;
    case 'dropdown':
      component = Dropdown;
      break;
    case 'slider':
      component = Slider;
      break;
    case 'checky':
      component = Checky;
      break;
    default:
      component = Input;
  }

  return (
    <Field
      component={component}
      id={id}
      style={{ fontSize: '1.1em', height: '50px' }}
      rows={rows}
      name={name}
      autoSize={true}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      label={'label'}
      {...props}
    />
  );
};

export default FormField;
