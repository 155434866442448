import React from 'react';
import _ from 'lodash';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import VisaEdit from './VisaEdit';
import VisaView from './VisaView';

import { Card, Col, Row, Button } from 'antd';

import {
  currentVisa,
  currentVisaValidUntil,
  isCpt,
  cptStartDate,
  cptEndDate,
  isOpt,
  optStartDate,
  optEndDate,
  isOptExt,
  optExtStartDate,
  optExtEndDate,
  isH1b,
  h1bStartDate,
  h1bEndDate,
  isH1bExt,
  h1bExtStartDate,
  h1bExtEndDate,
  isSecondMasters,
  secondMastersStateDate,
  secondMastersEndDate,
} from '../../constants/fieldIds.json';
import { withRouter } from 'react-router-dom';

class VisaIndex extends React.Component {
  state = {

    [currentVisa]: '',
    [currentVisaValidUntil]: '',

    [isCpt]: false,
    [cptStartDate]: '',
    [cptEndDate]: '',

    [isOpt]: false,
    [optStartDate]: '',
    [optEndDate]: '',

    [isOptExt]: false,
    [optExtStartDate]: '',
    [optExtEndDate]: '',

    [isH1b]: false,
    [h1bStartDate]: '',
    [h1bEndDate]: '',

    [isH1bExt]: false,
    [h1bExtStartDate]: '',
    [h1bExtEndDate]: '',

    [isSecondMasters]: false,
    [secondMastersStateDate]: '',
    [secondMastersEndDate]: '',

    isEditing: false,
    isLoading: true,
    isSaving: false,
    isNew: false
  };

  handleSave = (values) => {

    this.setState({
      isSaving: true,
    });

    this.props.firebase.onAuthUserListener(authUser => {
      delete values.isEditing
      delete values.isSaving
      delete values.isLoading
      delete values.isNew

      database()
        .ref('users/' + authUser.uid + `/visa/`)
        .set(values, () => {
          this.setState({...values, isEditing: false });
        });
    });
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/visa/`)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            this.setState({
              ...snapshot.val() ||  {},
              isLoading: false,
              isNew: false
            });
          }else{
            this.setState({
              isLoading: false,
              isNew: 'new'
            })
          }

          })
    },()=>{
      this.props.history.push(`/?redirect=visa`)
    });
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };


  render() {
    const { isEditing, isLoading } = this.state;

    return (
      <Card loading={isLoading}>
        {
            isEditing ? 
            <VisaEdit
                handleSave={this.handleSave} 
                handleCancel={this.handleCancel}
                values={this.state}
            /> :
            <VisaView 
                handleEdit={this.handleEdit}
                values={this.state}
            />
        }
      </Card>
    );
  }
}
export default withRouter(withFirebase(VisaIndex));
