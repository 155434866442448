

import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
// import FieldDisplay from '../FieldDisplay';
// import RoundsGrid from '../RoundsGrid';
// import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';
import { Card, Col, Row, Button } from 'antd';

const RawHTML = ({ children, className = '' }) => (
    <div id="source-html"
      style={{border:'1px dashed black', padding: '10px'}}
      dangerouslySetInnerHTML={{
        __html: children.replace(/\n/g, '<br />'),
      }}
    />
  );

  function downloadResumeAsDoc(){
    var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "+
         "xmlns:w='urn:schemas-microsoft-com:office:word' "+
         "xmlns='http://www.w3.org/TR/REC-html40'>"+
         "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header+document.getElementById("source-html").innerHTML+footer;
    
    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'document.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }


  // function downloadResumeAsPdf(){
  //   var HTML_Width = $(".html-content").width();
  //   var HTML_Height = $(".html-content").height();
  //   var top_left_margin = 15;
  //   var PDF_Width = HTML_Width + (top_left_margin * 2);
  //   var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
  //   var canvas_image_width = HTML_Width;
  //   var canvas_image_height = HTML_Height;

  //   var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  //   html2canvas($(".html-content")[0]).then(function (canvas) {
  //       var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //       var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
  //       for (var i = 1; i <= totalPDFPages; i++) { 
  //           pdf.addPage(PDF_Width, PDF_Height);
  //           pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
  //       }
  //       pdf.save("Your_PDF_Name.pdf");
  //       $(".html-content").hide();
  //   });
  // }

class ResumeView extends React.Component {
  render() {
    return (
      <Card>
       <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} style={{marginBottom: '10px'}}>
            <Col md={3} sm={12} xs={24}>
                <Button onClick={this.props.handleEdit}>Edit Resume</Button>
            </Col>
            {/* <Col md={3} sm={12} xs={24}>
                <Button onClick={downloadResumeAsPdf}>Download(.pdf)</Button>
            </Col> */}
            <Col md={14} sm={0} xs={0}/>
            <Col md={2} xs={24} sm={24}>
                {/* <Button onClick={downloadResumeAsDoc}>Download(.doc)</Button> */}
            </Col>
        </Row>
        <RawHTML>{this.props.values.resumeAsHtml}</RawHTML>
      </Card>
    );
  }
}
export default withFirebase(ResumeView);
