import React from 'react';
import { Slider, Row, Col, Checkbox } from 'antd';

function Checky({ value, onChange, id, sliderText="", ...props}) {

  // value = Number(value)
  
  return (
    <Row gutter={16}>
      <Col md={16}>
        <Checkbox
          // {...props}
            id={id}
            defaultValue= {false}
            checked={value}
            onChange={(e)=>{
              onChange({target: {value: e.target.checked, id}})
            }}
          />
        </Col>
      </Row>
  );
}

export default Checky;
