import React from 'react';
import { compose } from 'recompose';
import { Card, Icon, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { database } from 'firebase';
import { withFirebase } from '../Firebase';
import moment from 'moment';
import './round-grid.scss'

class RoundsGrid extends React.Component {
  state = {
    rounds: [],
    isLoading: true,
    interview : null
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/interviews/'+ this.props.interviewId)
        .once('value')
        .then(snapshot => {
          const interview = snapshot.val();
          if(interview){
            let rounds = Object.keys(interview.rounds || {}).map(key => ({
              id: key,
              ...interview.rounds[key],
            }));
            this.setState({
              isLoading: false,
              rounds,
              interview
            });
          }
        });
    });
  }

  render() {
    const { rounds } = this.state;
    return (
      <Card 
        style={{marginTop: '52px',}}
        className="add-shadow"
        loading={this.state.isLoading}
        title={<span className="card-header__label">{'Rounds'}</span>}
        >
        <div className="round-container" >
          {rounds.map(round => {
            const time = round.interviewFromTime ? moment(round.interviewFromTime).format('HH:mm a') : 'Not Set'
            const date = round.pre.interviewDate || 'Not Set'
            return (
              <Link to={`/round?iid=${this.props.interviewId}&rid=${round.id}`} key={round.id}>
                <Card
                  hoverable
                  className="round-card"
                >
                  <div className="round-card__content">
                    <span className="round-client__name">{`${round.pre.roundNumber || 'Not Set'}`}</span>
                    <span className="round-client__rounds">{`On: ${date}`}</span>
                  </div>
                </Card>
              </Link>
            );
          })}
          <Card
            loading={this.state.isLoading}
            className="round-card__add"
            hoverable
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   alignItems: 'center',
            //   justifyContent: 'center',
            //   margin: '10px',
            //   background:'linear-gradient(135deg, #fad961 0%,#f89838 100%)',
            //   borderRadius: '50px',
            // }}
          >
            <Link to={`/round?iid=${this.props.interviewId}&rid=new`}>
              {/* <Icon type="plus-circle" style={{ fontSize: '50px' }} />
              <div style={{ fontSize: '17px', color: 'white' }}>
                add new round
              </div> */}
              <Icon type="plus-circle" className="round-card__icon"/>
              <div className="round-card__text">
              add new round
              </div>
            </Link>
          </Card>
        </div>
      </Card>
    );
  }
}

export default withFirebase(RoundsGrid);