import React from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { Button } from 'antd';

const SignOutButton = ({ firebase }) => (
  <Button onClick={firebase.doSignOut}>
    <Link to={'/'}>Sign Out</Link>
  </Button>
);

export default withFirebase(SignOutButton);
