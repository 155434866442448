import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  jobTitle,
  jobLocation,
  jobDescription,
  referredBy,
  salary,
  interviewClient,
  implementationPartner,
  vendorDetails,
  readyToJoinIn,
  aboutCompany,
  interviewMainNotes,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button } from 'antd';
import getUrlParam from '../../utilities/getUrlParams';
import { Link } from 'react-router-dom';

const InterviewHeaderSchema = Yup.object().shape({
  [jobTitle]: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  [jobLocation]: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  [jobDescription]: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
});

class InterviewMainDetails extends React.Component {
  handleSaveInterview = () => {
    this.setState({
      isSaving: true,
    });

    let interviewId = getUrlParam('iid');
    if (interviewId === 'new') {
      interviewId = new Date().getTime();
    }

    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
        .set(this.state.editState, () => {
          this.setState({
            isSaving: false,
            isEditing: false,
            currentState: this.state.editState,
          });
          //this.props.history.push('/interviews');
        });
    });
  };

  // componentDidMount() {
  //   this.setState({
  //     ...this.props.value
  //   })
  // }

  // handleEdit = () => {
  //   this.setState({
  //     isEditing: true,
  //   })
  // }

  // handleCancel = () => {
  //   this.setState({
  //       editState: this.state.currentState,
  //       isEditing: false
  //   })
  // }

  getFieldDisplayProps = id => {
    return {
      value: this.props.values[id],
      id,
    };
  };

  interviewNavBar = () => {
    const {
      values: { interviewClient: clientName = 'New Client' },
    } = this.props;
    console.log(this.props);
    return (
      <>
        <span className="card-header__label">{clientName}</span>
      </>
    );
  };

  render() {
    // const { isEditing, isLoading, currentState } = this.props;
    const {
      values: { interviewClient: clientName = 'New Client' },
    } = this.props;
    return (
      <>
        <Link to="/interviews">
          <Button>{`back`}</Button>
        </Link>
        <Card
          className="add-shadow"
          style={{marginTop: '20px', minHeight:'80vh'}}
          extra={
            <Row gutter={16}>
              <Col md={12}>
                <Button onClick={this.props.handleDelete}>
                  <span>Delete</span>
                </Button>
              </Col>
              <Col md={12}>
                <Button onClick={this.props.handleEdit}>
                  <span>Edit</span>
                </Button>
              </Col>
            </Row>
          }
          title={this.interviewNavBar()}
        >
          <Row gutter={16}>
            <Col md={6}>
              <FieldDisplay {...this.getFieldDisplayProps(interviewClient)} />
            </Col>
            <Col md={6}>
              <FieldDisplay {...this.getFieldDisplayProps(jobTitle)} />
            </Col>
            <Col md={6}>
              <FieldDisplay {...this.getFieldDisplayProps(jobLocation)} />
            </Col>
            <Col md={6}>
              <FieldDisplay {...this.getFieldDisplayProps(salary)} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <FieldDisplay {...this.getFieldDisplayProps(implementationPartner)} />
            </Col>
            <Col md={12}>
              <FieldDisplay {...this.getFieldDisplayProps(vendorDetails)} />
            </Col>
          </Row>

          <Row>
            <FieldDisplay {...this.getFieldDisplayProps(jobDescription)} />
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <FieldDisplay {...this.getFieldDisplayProps(readyToJoinIn)} />
            </Col>
            <Col md={12}>
              <FieldDisplay {...this.getFieldDisplayProps(referredBy)} />
            </Col>
          </Row>
          <Row>
            <FieldDisplay {...this.getFieldDisplayProps(interviewMainNotes)} />
          </Row>
          <Row>
            <FieldDisplay {...this.getFieldDisplayProps(aboutCompany)} />
          </Row>
        </Card>
      </>
    );
  }
}
export default withFirebase(InterviewMainDetails);
