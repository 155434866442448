import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import InputMaker from '../Form';
import { withFirebase } from '../Firebase';

import {
  currentVisa,
  currentVisaValidUntil,
  isCpt,
  cptStartDate,
  cptEndDate,
  isOpt,
  optStartDate,
  optEndDate,
  isOptExt,
  optExtStartDate,
  optExtEndDate,
  isH1b,
  h1bStartDate,
  h1bEndDate,
  isH1bExt,
  h1bExtStartDate,
  h1bExtEndDate,
  isSecondMasters,
  secondMastersStateDate,
  secondMastersEndDate,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Slider } from 'antd';

class VisaEdit extends React.Component {
  render() {
    return (
      <>
        <Formik
          initialValues={this.props.values}
          validateOnBlur
          //validationSchema={InterviewHeaderSchema}
        >
          {props => {
            const {
              values,
              handleChange,
              handleBlur,
              errors,
              setErrors,
            } = props;

            //const isValid = InterviewHeaderSchema.isValidSync(values);

            const getInputProps = id => ({
              id,
              value: values[id],
              error: errors[id],
              onChange: handleChange,
              onBlur: handleBlur,
            });

            const renderRows = (firstCol, SecondCol, thirdCol) => {
              return (
                <Row gutter={16}>
                  <Col md={4}>
                    <InputMaker
                      type="checky"
                      {...getInputProps(firstCol)}
                    />
                  </Col>
                  {values[firstCol] && (
                    <>
                      <Col md={4}>
                        <InputMaker
                          type="date"
                          {...getInputProps(SecondCol)}
                        />
                      </Col>
                      <Col md={4}>
                        <InputMaker
                          type="date"
                          {...getInputProps(thirdCol)}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              );
            };

            const handleSave = () => this.props.handleSave(values);

            return (
              <>
                <Card
                  extra={
                    <Row gutter={16}>
                      <Col md={12}>
                        <Button onClick={handleSave} type="primary">
                          Save
                        </Button>
                      </Col>
                      <Col md={12}>
                        <Button onClick={this.props.handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  }
                  title={
                    <span className="card-header__label">
                      {'Visa'}
                    </span>
                  }
                >
                  <Row gutter={16}>
                    <Col md={4}>
                      <InputMaker type="dropdown" options={['F1','H1B']} {...getInputProps(currentVisa)} />
                    </Col>
                    <Col md={4}>
                      <InputMaker
                        type="date"
                        {...getInputProps(currentVisaValidUntil)}
                      />
                    </Col>
                  </Row>

                  {renderRows(isCpt, cptStartDate, cptEndDate)}
                  {renderRows(isOpt, optStartDate, optEndDate)}
                  {renderRows(
                    isOptExt,
                    optExtStartDate,
                    optExtEndDate,
                  )}
                  {renderRows(isH1b, h1bStartDate, h1bEndDate)}
                  {renderRows(
                    isH1bExt,
                    h1bExtStartDate,
                    h1bExtEndDate,
                  )}
                  {renderRows(
                    isSecondMasters,
                    secondMastersStateDate,
                    secondMastersEndDate,
                  )}
                </Card>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
export default withFirebase(VisaEdit);
