import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { Card, Button, Row, Col, Menu, Icon } from 'antd';
import { database } from 'firebase';
import { withFirebase } from '../../components/Firebase';
import './navigation.scss';

const { SubMenu } = Menu;
let clicks = 0;
const Navigation = props => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} {...props} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const publicLinks = [
  {
    tabName: 'About',
    path: '/personalDetails',
    icon: 'mail',
  },
  {
    tabName: 'Resume',
    path: '/resume',
    icon: 'mail',
  },
  {
    tabName: 'Projects',
    path: '/projects',
    icon: 'mail',
  },
];

const privateLinks = [
  {
    tabName: 'Visa',
    path: '/visa',
    icon: 'mail',
  },
  {
    tabName: 'Employer',
    path: '/employer',
    icon: 'mail',
  },
  // {
  //   tabName: 'RESUME',
  //   path: '/resume',
  //   icon: 'mail',
  // },
  {
    tabName: 'Education',
    path: '/education',
    icon: 'mail',
  },
  {
    tabName: 'Interviews',
    path: '/interviews',
    icon: 'mail',
  },
  {
    tabName: 'Calendar',
    path: '/booking',
    icon: 'mail',
  },
  {
    tabName: 'Comments',
    path: '/comments',
    // icon: 'bubble',
  },
  {
    tabName: 'Overview',
    path: '/profile',
    icon: 'mail',
  },
  // {
  //   tabName: 'Remote Access',
  //   path: '/connect',
  //   icon: 'mail',
  // },
  // {
  //   tabName: 'PROFILE',
  //   path: '/profile',
  //   icon: 'mail',
  // },
  // {
  //   tabName: 'HELP',
  //   path: '/live-student',
  //   icon: 'mail',
  // },
  // {
  //   tabName: 'REPORT BUG',
  //   path: '/report-a-bug',
  //   icon: 'mail',
  // },
];

class NavigationAuth extends React.Component {
  state = {
    isPro: false,
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/config')
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            const config = snapshot.val();
            const { isPro = false } = config;
            this.setState({
              isPro,
            });
          }
        });
    });
  }

  enableProUser = () => {
    clicks++;
    if (clicks > 10) {
      const userId = this.props.authUser.uid;
      const isPro = this.state.isPro;
      clicks = 0;
      database()
        .ref('users/' + userId + '/config/isPro')
        .set(!isPro, () => {
          this.setState({
            isPro: !isPro,
          });
        });
    }
  };

  render() {
    const { isPro } = this.state;
    let linksToRender = [...publicLinks];
    if (isPro || true) {
      // Remove this to control pro users
      linksToRender = [...linksToRender, ...privateLinks];
    }

    if (window.location.href.split('/r/').length == 2) {
      return null;
    }

    return (
      <>
        <Menu
          selectedKeys={[`/${window.location.href.split('/')[3]}`]}
          mode="horizontal"
          style={{ paddingTop: '20px', paddingLeft: '20px' }}
        >
          <Menu.Item onClick={this.enableProUser}>
            <Col xs={0} md={3}>
              <span
                style={{
                  fontSize: '25px',
                  fontWeight: '800',
                  color: '#424242',
                }}
              >
                Linterviews
              </span>
            </Col>
            <Col xs={3} md={0}>
              <span style={{ fontSize: '25px', fontWeight: '800' }}>
                IL
              </span>
            </Col>
          </Menu.Item>
          {linksToRender.map(link => {
            return (
              <Menu.Item key={link.path}>
                <Link to={link.path}>
                  {/* {link.tabName} */}
                  <Row>
                    {/* <Col md={6} style={{margin: '5px'}}>
                      <Icon type={link.icon} style={{fontSize: '15px'}}/>
                    </Col> */}
                    <Col md={6}>{link.tabName}</Col>
                  </Row>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item onClick={this.enableProUser}>
            <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
              {this.props.authUser.email}
            </span>
          </Menu.Item>
          <Menu.Item>
            <SignOutButton />
          </Menu.Item>
        </Menu>
      </>
    );
  }
}
const NavigationNonAuth = () => (
  <div>
    {/* <h1>You are currently Signed out, changes will not be saved.</h1>
      <Button style={{margin: '5px'}}>
        <NavLink activeClassName="active" to={'/'}>Signin</NavLink>
      </Button>
      <h1>here</h1> */}
  </div>
);

export default withFirebase(Navigation);
