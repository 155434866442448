import React from 'react'
import {get} from 'lodash'

import {
    Form,
    Icon,
    Input,
    Row,
    Col,
    Card,
    Tooltip,
    Button,
    Select,
    Radio,
    TimePicker,
  } from 'antd';

import verbiage from '../../constants/verbage.json'
import FieldName from './FieldName';
import FieldValue from './FieldValue';

const FieldDisplay = ({
    id = "notAvailable",
    label = get(verbiage,`${id}.fieldLabel`,''),
    helpText = get(verbiage,`${id}.helpText`,''),
    placeholder = get(verbiage,`${id}.placeHolder`,''),
    showField =  get(verbiage, `${id}.showField`, true),
    error,
    required = false,
    isEditing = true,
    ...props
}) => {
  if(showField === false){
    return null
  }
  return (
    <Row gutter={16} style={{margin:'5px'}}>
        <FieldName
            label={label}
            {...props}
        />
        <FieldValue
            {...props}
        />
    </Row>
  );
};

export default FieldDisplay
