import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  firstName,
  lastName,
  phone,
  email,
  totalExpYears,
  totalExpMonths,
  USTotalExpYears,
  USTotalExpMonths,
  personalSummary
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu } from 'antd';

const RawHTML = ({ children, className = '' }) => (
  <div id="source-html"
    dangerouslySetInnerHTML={{
      __html: children.replace(/\n/g, '<br />'),
    }}
  />
);

class PublicResume extends React.Component {

  state = {
      resumeAsHtml: '',
      publicLink: '',
      isLoading: true,
      isResumeAvailable: false,
  }

  componentDidMount() {
      const publicResumeId = window.location.href.split('r/')[1]
      database()
        .ref('public/' + publicResumeId)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            this.setState({
              ...snapshot.val(),
              isResumeAvailable: true,
              isLoading: false
            });
          }else{
            this.setState({
              isLoading: false
            })
          }
        });
  }

//   handlePublish = () => {
//     this.props.firebase.onAuthUserListener(authUser => {
//         database()
//           .ref('public/' + this.state.publicLink)
//           .set(this.state.resume, ()=>{
//               alert('Success')
//           })
//       });

//   }

//   handleSave = () => {
//     this.props.firebase.onAuthUserListener(authUser => {
//         database()
//           .ref('users/' + authUser.uid + '/resumeAsJson')
//           .set(this.state.resume, ()=>{
//               alert('Success')
//           })
//       });
//   }

  render() {
    const { isResumeAvailable, resumeAsHtml, isLoading } = this.state
    return (
      <Card
      style={{margin: '10px'}}
        loading={isLoading}
        className="add-shadow">
            {/* <input value={this.state.resume} onChange={(e)=>{this.setState({resume: e.target.value})}} />
            <input value={this.state.publicLink} onChange={(e)=>{this.setState({publicLink: e.target.value})}} />
            <Button onClick={this.handleSave}> Save </Button>
            <Button onClick={this.handlePublish}> Publish </Button> */}
            {
              isResumeAvailable ? 
              <div style={{whiteSpace:'pre-wrap', whiteSpace: 'pre-line'}}> 
                <RawHTML>{resumeAsHtml}</RawHTML>
              </div> : 
              <div>No Resume found here</div> 
            }
      </Card>
    );
  }
}
export default withFirebase(PublicResume);
