import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';

import {
  Form,
  Icon,
  Input,
  Col,
  Row,
  Card,
  Tooltip,
  Button,
  Select
} from 'antd';

const iQuestions = [
  "https://raw.githubusercontent.com/symmetriccurve/Interview-questions/master/Reactjs%20Question.md",
  "https://raw.githubusercontent.com/symmetriccurve/Interview-questions/master/Javascript%20Questions.md",
  "https://raw.githubusercontent.com/symmetriccurve/Interview-questions/master/HTML%20Questions.md",
  "https://raw.githubusercontent.com/symmetriccurve/Interview-questions/master/CSS%20Questions.md",
  "https://raw.githubusercontent.com/symmetriccurve/Interview-questions/master/Work%20Flow%20Questions.md",
  "https://raw.githubusercontent.com/symmetriccurve/Interview-questions/master/Scenario%20Based%20Questions.md"
]

const { Option } = Select;

class QuestionPicker extends React.Component {
  state = {
    questions: [],
  };

  componentDidMount() {
		// const urlParams = this.props.location.search.split('=')[ 1 ]
		// const searchStr = urlParams ? urlParams.replace(/%20/g,' ') : ''
		// this.setState({ searchStr })
		let calls = []
		let markDown = []
		iQuestions.forEach(eachURL=>{
			const call = fetch(eachURL).then(res=>res.text())
			calls.push(call)
		})
		Promise.all(calls)
		.then(topics=>{
			topics.forEach(topic=>{
				markDown = markDown.concat(topic.split('---'))
			})
			this.setState({ 
				questions: markDown,
			})
		})
		.catch(err=>{
			console.log('Error fetching interview questions',err)
		})
	}

  render() {
    return (
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={this.props.onChange}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          option.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          this.state.questions.map(each=><Option key={each}>{each}</Option>)
        }
      </Select>
    );
  }

}

export default QuestionPicker;
