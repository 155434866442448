import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Dropdown,
  Menu,
  Button,
} from 'antd';
import { withFirebase } from './components/Firebase';
import { AuthUserContext } from './components/Session';

const userExperienceSchema = Yup.object().shape({
  totalYearsOfExpInMonths: Yup.number().required('Required'),
  USYearsOfExpInMonths: Yup.number().required('Required'),
  readyToJoinInDays: Yup.string(),
});

const totalExpOptions = '#'
  .repeat(120)
  .split('')
  .map((item, index) => (
    <option key={index} value={index + 1}>{`${index +
      1} Months`}</option>
  ));
const USExpOptions = '#'
  .repeat(120)
  .split('')
  .map((item, index) => (
    <option key={index} value={index + 1}>{`${index +
      1} Months`}</option>
  ));
const ReadyToJoinInOptions = '#'
  .repeat(14)
  .split('')
  .map((item, index) => (
    <option key={index} value={index + 1}>{`${index +
      1} Days`}</option>
  ));

class Experience extends React.Component {
  state = {
    totalYearsOfExpInMonths: 0,
    USYearsOfExpInMonths: 0,
    readyToJoinInDays: 0,
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/experience')
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            var userExperience = snapshot.val();
            this.setState(userExperience);
          }
        });
    });
  }

  saveToFirebase = authUser => {
    const values = this.state;
    const userId = authUser.uid;
    database()
      .ref('users/' + userId + '/experience')
      .set(values);
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <Formik
              initialValues={this.state}
              validateOnBlur
              validationSchema={userExperienceSchema}
              enableReinitialize
            >
              {props => {
                const {
                  values,
                  // dirty,
                  // isSubmitting,
                  handleSubmit,
                  handleReset,
                  handleChange,
                  handleBlur,
                  errors,
                  // validateOnBlur,
                  // touched,
                } = props;

                const UpdateForm = e => {
                  handleChange(e);
                  const {
                    target: { id, value },
                  } = e;
                  this.setState(
                    {
                      [id]: value,
                    },
                    () => {
                      this.saveToFirebase(authUser);
                    },
                  );
                };
                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Card
                        style={{
                          background: '#ECECEC',
                          padding: '10px',
                        }}
                      >
                        <Card>
                          <Row gutter={16}>
                            <Row md={3}>
                              <Form.Item
                                label={
                                  <span>
                                    Total Exp in Months&nbsp;
                                    <Tooltip title="Seriously ?? 😃">
                                      <Icon type="question-circle-o" />
                                    </Tooltip>
                                  </span>
                                }
                              >
                                <select
                                  value={
                                    this.state[
                                      'totalYearsOfExpInMonths'
                                    ]
                                  }
                                  onChange={UpdateForm}
                                  id={'totalYearsOfExpInMonths'}
                                >
                                  {totalExpOptions}
                                </select>
                                <span className="error-message">
                                  {errors['totalYearsOfExpInMonths']}
                                </span>
                              </Form.Item>
                            </Row>
                            <Row md={3}>
                              <Form.Item
                                label={
                                  <span>
                                    US Experience in Months&nbsp;
                                    <Tooltip title="Got you !!😃">
                                      <Icon type="question-circle-o" />
                                    </Tooltip>
                                  </span>
                                }
                              >
                                <select
                                  value={
                                    this.state['USYearsOfExpInMonths']
                                  }
                                  onChange={UpdateForm}
                                  id={'USYearsOfExpInMonths'}
                                >
                                  {USExpOptions}
                                </select>
                                <span className="error-message">
                                  {errors['USYearsOfExpInMonths']}
                                </span>
                              </Form.Item>
                            </Row>
                            <Row md={3}>
                              <Form.Item label="Ready to join in days">
                                <select
                                  value={
                                    this.state['readyToJoinInDays']
                                  }
                                  onChange={UpdateForm}
                                  id={'readyToJoinInDays'}
                                >
                                  {ReadyToJoinInOptions}
                                </select>
                                <span className="error-message">
                                  {errors['readyToJoinInDays']}
                                </span>
                              </Form.Item>
                            </Row>
                          </Row>
                        </Card>
                      </Card>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Experience);
