
import React from 'react';
import _ from 'lodash';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';
import draftToHtml from 'draftjs-to-html';
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './resume.scss';


import ResumeEdit from './ResumeEdit';
import ResumeView from './ResumeView';

import { Card, Col, Row, Button } from 'antd';
import Publish from './Publish';


class ResumeIndex extends React.Component {
  state = {
    contentURL: '',
    isLoading: true,
    isEditing: false,
    isPublic: false,
    publicResumeId: '',
    resumeAsHtml: '<p>Add Your Resume here</p>',
    resumeAsJsonForEditor: null
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/resume')
        .once('value')
        .then(snapshot => {
            const { 
                resumeAsHtml = '<p>Add Your Resume here</p>',
                resumeAsJsonForEditor = null,
                publicResumeId = '',
                isPublic = false,
              } = snapshot.val() || {}

            this.setState({
              isLoading: false,
              resumeAsHtml,
              resumeAsJsonForEditor,
              publicResumeId,
              isPublic
            });

        });
    });
  }

  handleSave = (updatedResume) => {
    this.props.firebase.onAuthUserListener(authUser => {
      const resumeAsHtml = draftToHtml(convertToRaw(updatedResume.getCurrentContent()))
      database()
        .ref('users/' + authUser.uid + '/resume')
        .update(
          {
            resumeAsHtml,
            resumeAsJsonForEditor: JSON.stringify(
              convertToRaw(
                updatedResume.getCurrentContent(),
              ),
            ),
          },
          () => {
            this.setState({
              isEditing: false,
              resumeAsHtml,
              resumeAsJsonForEditor: JSON.stringify(
                convertToRaw(
                  updatedResume.getCurrentContent(),
                ),
              )
            });
            alert('success');
          },
        );
    });
}



  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const { isEditing, isLoading } = this.state;

    return (
      <Card loading={isLoading} className="add-shadow" style={{margin: '20px'}}>
        {
            isEditing ? 
            <ResumeEdit
                handleSave={this.handleSave} 
                handleCancel={this.handleCancel}
                values={this.state}
            /> :
            <>
              {/* <Publish 
                values={this.state} /> */}
              <ResumeView
                  handleEdit={this.handleEdit}
                  values={this.state}
              />
            </>
        }
      </Card>
    );
  }
}
export default withFirebase(ResumeIndex);
