import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Dropdown,
  Menu,
  Button,
  DatePicker,
  Tabs,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Project from './Project';
const { Text } = Typography;
const { TabPane } = Tabs;

export const FlowActions = ({ prev, next }) => {
  return (
    <Row style={{ zIndex: 10, display: 'flex' }}>
      <Col style={{ display: 'flex', flex: 1 }}>
        <Button>
          <Link to={'/resume'}>Previous</Link>
        </Button>
      </Col>
      <Col
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Button type="primary">
          <Link to={'/visa'}>Next</Link>
        </Button>
      </Col>
    </Row>
  );
};

class Projects extends React.Component {
  state = {
    projectsCount: 0,
    activeTab: 'project1',
  };

  componentDidMount() {
    const [, activeTab = 'project1'] = window.location.href.split(
      '?',
    );
    this.setState({
      activeTab,
    });
  }

  handleTabSelection = activeTab => {
    this.setState({
      activeTab,
    });
  };

  render() {
    return (
      <Card style={{ background: '#ECECEC' }}>
        <Card>
          {/* <FlowActions /> */}
          <Text
            mark
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {` Changes/Edits are automatically saved on projects, no save
            required. `}
          </Text>
          <Tabs
            activeKey={this.state.activeTab}
            defaultActiveKey={this.state.activeTab}
            tabPosition={'top'}
            onChange={this.handleTabSelection}
          >
            <TabPane tab="Latest Project" key="project1">
              <Project
                {...this.props}
                projectId={'project1'}
                current
              />
            </TabPane>
            <TabPane tab="Project 2" key="project2">
              <Project {...this.props} projectId={'project2'} />
            </TabPane>
            <TabPane tab="Project 3" key="project3">
              <Project {...this.props} projectId={'project3'} />
            </TabPane>
            <TabPane tab="Project 4" key="project4">
              <Project {...this.props} projectId={'project4'} />
            </TabPane>
            <TabPane tab="Project 5" key="project5">
              <Project {...this.props} projectId={'project5'} />
            </TabPane>
            <TabPane tab="Project 6" key="project6">
              <Project {...this.props} projectId={'project6'} />
            </TabPane>
            <TabPane tab="Project 7" key="project7">
              <Project {...this.props} projectId={'project7'} />
            </TabPane>
            <TabPane tab="Project 8" key="project8">
              <Project {...this.props} projectId={'project8'} />
            </TabPane>
            <TabPane tab="Project 9" key="project9">
              <Project {...this.props} projectId={'project9'} />
            </TabPane>
          </Tabs>
        </Card>
      </Card>
    );
  }
}
export default Projects;
