import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Icon, Button } from 'antd';

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: <div>Hello</div>,
  }));

  

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'grey',
  padding: grid,
  //width: 250,
});

export default class App extends Component {
  constructor(props, context) {
    super(props, context);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      items: [
        {
            isVisible: false,
            isEditing: false,
            content: 'someContent',
            id: '0'
        },
        {
            isVisible: false,
            isEditing: false,
            content: 'someContent',
            id: '2'
        },
        {
            isVisible: true,
            isEditing: false,
            content: 'someContent',
            id: '3'
        },
        {
            isVisible: false,
            isEditing: false,
            content: 'someContent',
            id: '4'
        },
        {
            isVisible: false,
            isEditing: false,
            content: 'someContent',
            id: '5'
        },
        
    ]
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      items,
    });
  }

  handleCellChange = (index,value) => {
      const state = Object.assign({},this.state)
      let { items } = state
      items[index].content = value
      this.setState({
          items
      })
  }

  handleCellEdit = (index) => {
    const state = Object.assign({},this.state)
    let { items } = state
    items[index].isEditing = !items[index].isEditing
    this.setState({
        items
    })
 }

  handleCellVisibility = (index) => {
    const state = Object.assign({},this.state)
    let { items } = state
    items[index].isVisible = !items[index].isVisible
    this.setState({
        items
    })
 }

  renderRow = (row, index )=> {
    const { content ,  isEditing, isVisible } = row

    return(
        <Row>
            <Col md={21}>
                {
                    isEditing ?
                        <input value={content} onChange={(e)=>this.handleCellChange(index,e.target.value)}/> :
                    (isVisible ? <div style={{backgroundColor:'lightgray'}} >{content}</div>: <div>{content}</div>)
                }
            </Col>
            <Col md={1}>
                { isEditing ? <Icon type="check" onClick={()=>this.handleCellEdit(index)}/> : <Icon type="edit" onClick={()=>this.handleCellEdit(index)}/>}
            </Col>
            <Col md={1}>
                { isVisible ? <Icon type="eye-invisible" onClick={()=>this.handleCellVisibility(index)}/> : <Icon type="eye" onClick={()=>this.handleCellVisibility(index)}/> }
            </Col>
            <Col md={1}><Icon type="delete" /></Col>
        </Row>
    )
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div
              id="source-html"
              ref={droppableProvided.innerRef}
              style={getListStyle(droppableSnapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={getItemStyle(
                        draggableSnapshot.isDragging,
                        draggableProvided.draggableProps.style,
                      )}
                    >
                      {this.renderRow(item, index)}
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}