import React from 'react';
import _ from 'lodash';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import PersonalDetailsEdit from './PersonalDetailsEdit';
import PersonalDetailsView from './PersonalDetailsView';
import Resume from '../Resume/ResumeIndex';

import { Card, Col, Row, Button, Modal } from 'antd';
import ResumeUploadIndex from '../ResumeUpload/ResumeUploadIndex';

class PreInterview extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    totalExpYears: 0,
    totalExpMonths: 0,
    USTotalExpYears: 0,
    USTotalExpMonths: 0,
    personalSummary:
      'My full name is Johnathan Jackson.I have 7 Year of experience working as a frontend developer. My areas of expertise are react on the front end and spring boot on back end. I started my career back in india as HTMl, CSS and Javascript developer at ABC systems, where i was building there public facing website then i got an opportunity to do masters in the us from YUZ university.then i started working from bank XYZ as a frontend developer where i learned about react and security around the web technologies then i moved on to UPS where i was working on data intensive front end applications using react and redux as a state management library and I have used enzyme for unit testing and webpack for building and bundling the application.I am currently working with Verizon in building a Permission based dashboard portal for store representatives across the verizon facilities to manage permission of the associates on the customer data, the application is build from the scratch using React and Redux, I architected the project from the base level and introduced many features and enhancements. My day to day responsibilities include code reviews, code merges, deployments, meeting with backend and collaborating with UX team on the new features and work with the developers to estimate stories and provide support in completion of tasks. ',
    isEditing: false,
    isLoading: true,
    isSaving: false,
    isNew: false,
  };

  handleSave = values => {
    this.setState({
      isSaving: true,
    });

    // let roundId = getUrlParam('rid');
    // let interviewId = getUrlParam('iid');

    // if (roundId === 'new') {
    //   roundId = new Date().getTime();
    // }

    this.props.firebase.onAuthUserListener(authUser => {
      delete values.isEditing;
      delete values.isSaving;
      delete values.isLoading;
      delete values.isNew;

      database()
        .ref('users/' + authUser.uid + `/personalDetails`)
        .set(values, () => {
          this.setState({
            ...values,
            isSaving: false,
            isEditing: false,
          });
        });
    });
  };

  componentDidMount() {
    // fetch('https://www.googleapis.com/oauth2/v4/token', {
    //   method: 'post',
    //   body: JSON.stringify({
    //     client_id: '875204572089-k9f4ftukftv7fr8vkheb4cu5v02miq2u.apps.googleusercontent.com',
    //     client_secret: 'f8mRYipLsPftcmC4MvSDSO-E',
    //     refresh_token: 'REFRESH_TOKEN',
    //     grant_type: 'authorization_code',
    //   }),
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((json) => console.log(json));

    // fetch(
    //   'https://www.googleapis.com/calendar/v3/users/me/calendarList',
    //   {
    //     method: 'get',
    //     headers: {
    //       Authorization:
    //         'Bearer AIzaSyD5jla59tY6TG6uI6EgeEhYZYzFntdWlhg',
    //     },
    //   },
    // )
    //   .then((res) => res.json())
    //   .then((json) => console.log(json));

    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/personalDetails`)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            this.setState({
              ...(snapshot.val() || {}),
              isLoading: false,
              isNew: false,
            });
          } else {
            this.setState({
              isLoading: false,
              isNew: 'new',
            });
          }
        });
    });
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };

  render() {
    const { isEditing, isLoading } = this.state;

    return (
      <>
        <Card
          loading={isLoading}
          title={
            isLoading
              ? 'Please refesh the page if it is taking longer than expected'
              : ''
          }
        >
          <PersonalDetailsView
            handleEdit={this.handleEdit}
            values={this.state}
          />
          <PersonalDetailsEdit
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            values={this.state}
          />
        </Card>
        {/* <ResumeUploadIndex /> */}
        {/* <Resume /> */}
      </>
    );
  }
}
export default withFirebase(PreInterview);
