import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import { AuthUserContext } from './components/Session';
import { get } from 'lodash';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Select,
  Radio,
  TimePicker,
} from 'antd';
import { withFirebase } from './components/Firebase';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datepicker from './components/Datepicker';
import VisaViewer from './VisaViewer';
import DuringInterview from './components/DuringInterview/DuringInterviewIndex';
import { DropDownMenu } from 'material-ui';
const { Option } = Select;

class Profile extends React.Component {
  state = {
    profile: {
      projects: {},
    },
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            console.log('snapshot.val(): ', snapshot.val());
            this.setState({
              profile: snapshot.val(),
            });
          }
        });
    });
  }

  render() {
    const { profile } = this.state;
    if (!profile) return null;

    const firstName = get(
      profile,
      'personalDetails.firstName',
      'N/a',
    );
    const lastName = get(profile, 'personalDetails.lastName', 'N/a');
    const totalExpMonths = get(
      profile,
      'personalDetails.totalExpMonths',
      'N/a',
    );
    const totalExpYears = get(
      profile,
      'personalDetails.totalExpYears',
      'N/a',
    );
    const USTotalExpMonths = get(
      profile,
      'personalDetails.USTotalExpMonths',
      'N/a',
    );
    const USTotalExpYears = get(
      profile,
      'personalDetails.USTotalExpYears',
      'N/a',
    );
    const phone = get(profile, 'personalDetails.phone', 'N/a');
    const email = get(profile, 'personalDetails.email', 'N/a');

    const projects = get(profile, 'projects', {});
    const visa = get(profile, 'visa', null);
    const interviewDetails = get(profile, 'interviewDetails', {});

    return (
      <Card style={{ background: '#ECECEC' }}>
        <Card
          extra={
            <Link to="/personalDetails">
              <Icon type="edit" />
            </Link>
          }
          title={`First Name: ${firstName} Last Name: ${lastName}`}
        >
          <h5>
            <b>Total Experience: </b> {totalExpYears} Years{' '}
            {totalExpMonths} <b>Months, USA Experience:</b>{' '}
            {USTotalExpYears} Years {USTotalExpMonths} Months
          </h5>
          <h5>
            <b>Phone:</b> {phone}
          </h5>
          <h5>
            <b>Email:</b> {email}
          </h5>
        </Card>
        {/* <DuringInterview /> */}

        {/* Projects */}
        <div style={{ display: 'flex', overflowX: 'scroll' }}>
          {Object.keys(projects).map(key => {
            if (projects[key].isEnabled === 'false') {
              return null;
            }

            const {
              clientName = 'N/a',
              clientStreet = 'N/a',
              clientCity = 'N/a',
              clientState = 'N/a',
              clientZipCode = 'N/a',
              startDate = 'N/a',
              endDate = 'N/a',
              visaStatusAtTimeOfProject = 'N/a',
              projectName = 'N/a',
              aboutProject = 'N/a',

              homeStreet = 'N/a',
              homeCity = 'N/a',
              homeState = 'N/a',
              homeZipCode = 'N/a',

              notes = 'N/a',
              trainerNotes = 'N/a',
            } = projects[key];

            return (
              <Card
                style={{ margin: '1% 1% 1% 0%', minWidth: '300px' }}
                extra={
                  <Link to={`/projects?${key}`}>
                    <Icon type="edit" />
                  </Link>
                }
                title={clientName}
              >
                <h5>Location</h5>
                <h4>{`${clientStreet} ${clientCity} ${clientState} ${clientZipCode}`}</h4>

                <h5>Duration</h5>
                <h4>
                  {startDate} to {endDate}
                </h4>
                <h4>
                  {moment(endDate).diff(moment(startDate), 'months')}{' '}
                  Months
                </h4>
                <h6>
                  {moment(endDate).diff(moment(startDate), 'years')}{' '}
                  Years
                </h6>

                <h5>Visa</h5>
                <h4>{`${visaStatusAtTimeOfProject}`}</h4>

                <h5>Project Name</h5>
                <h4>{`${projectName}`}</h4>

                <h5>About Project</h5>
                <h6>{`${aboutProject}`}</h6>

                <h5>Trainer Notes</h5>
                <h6>{`${trainerNotes}`}</h6>

                <h5>Home</h5>
                <h6>{`${homeStreet} ${homeCity} ${homeState} ${homeZipCode}`}</h6>
              </Card>
            );
          })}
        </div>

        {/* Visa */}
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            overflowX: 'scroll',
          }}
        >
          {visa && <VisaViewer visa={visa} />}
        </div>
      </Card>
    );
  }
}
export default withFirebase(Profile);
