import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import { GlobalHotKeys } from 'react-hotkeys';
import { useOnline } from 'react-browser-hooks';
import {
  Form,
  Icon,
  Input,
  Col,
  Row,
  Card,
  Tooltip,
  Button,
  Select,
} from 'antd';
import Firebase, { withFirebase } from './components/Firebase';
import { Link } from 'react-router-dom';
import StatusIcon from './components/StatusIcon';
import Status from './components/Status';
import QuestionPicker from './QuestionPicker';
const { Option } = Select;

// window.onbeforeunload = function (event) {
//   alert('dad')
//   return 'message';
//   var message = 'Important: Please click on \'Save\' button to leave this page.';
//   if (typeof event == 'undefined') {
//       event = window.event;
//   }
//   if (event) {
//       event.returnValue = message;
//   }
//   return message;
// };

const keyMap = {
  ADD: 'shift+enter',
};

// const handlers = {
//   ADD: event => console.log('Move up hotkey called!'),
// };

const userPersonalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  lastName: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  phone: Yup.string()
    .matches(/^[1-9]\d{9}$/, {
      message: 'invalid',
      excludeEmptyString: false,
    })
    .required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
});

class PersonalDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      trainerNotes: '',
      studentNotes: '',
    };
    this.inputRef = React.createRef();
  }

  handlers = {
    ADD: event => this.addNewQuestion(),
  };

  componentDidMount() {
    //var ref = firebase.database().ref('live-trainer');
    this.inputRef.current.focus();
    database()
      .ref('live-student')
      .on('value', snapshot => {
        if (snapshot.val()) {
          this.setState({
            studentNotes: snapshot.val().value,
          });
        }
      });
  }

  saveToFirebaseFromTrainer = input => {
    const value = input.target ? input.target.value : input;
    this.setState({
      trainerNotes: value,
    });
    //this.props.firebase.onAuthUserListener(authUser => {
    // const values = this.formValues;
    // const errors = this.formErrors;
    // const userId = authUser.uid;
    // const isValid = Object.entries(errors).length === 0
    database()
      .ref('live-trainer/value')
      .set({
        value,
      },(error)=>{
        console.log(error)
      });
    // });
  };

  
  addNewQuestion = e => {
    const { trainerNotes: value } = this.state;
    if (value) {
      this.props.firebase.onAuthUserListener(authUser => {
        // const values = this.formValues;
        // const errors = this.formErrors;
        // const userId = authUser.uid;
        // const isValid = Object.entries(errors).length === 0
        database()
          .ref('live-trainer')
          .push({
            value,
          });

        database()
          .ref('live-trainer/value')
          .set(
            {
              value: 'waiting for question. . .',
            },
            () => {
              this.setState({
                trainerNotes: '',
              });
            },
          );
      });
    }
  };

  clearChat= e => {
    const { trainerNotes: value } = this.state;
      this.props.firebase.onAuthUserListener(authUser => {
        // const values = this.formValues;
        // const errors = this.formErrors;
        // const userId = authUser.uid;
        // const isValid = Object.entries(errors).length === 0
        database()
          .ref('live-trainer')
          .set({
            value: {
              value: 'waiting for question . .'
            },
          });
      })
  };

  handleQuestionPick = answer => {
    this.saveToFirebaseFromTrainer(answer)
    this.setState({
      trainerNotes: answer
    })
  }

  render() {
    return (
      <Card
        style={{
          background: '#ECECEC',
          padding: '10px',
        }}
      >
        <QuestionPicker onChange={this.handleQuestionPick}/>
        <Status />
        {/* <GlobalHotKeys
          keyMap={keyMap}
          handlers={this.handlers}
          filter={event => {
            return true;
          }}
        > */}
          <Input.TextArea
            style={{height: '300px'}}
            ref={this.inputRef}
            onChange={this.saveToFirebaseFromTrainer}
            value={this.state.trainerNotes}
          />
          <Button onClick={this.addNewQuestion}>Next</Button>
          <Button onClick={this.clearChat}>Clear All</Button>
        {/* </GlobalHotKeys> */}
        <h1>{this.state.studentNotes}</h1>
      </Card>
    );
  }

  // componentWillUnmount() {
  //   database()
  //     .ref('live-trainer')
  //     .onDisconnect()
  //     .set({
  //       status: 'offline',

  //       // alert("Do you want to close")
  //       // // if (snapshot.val()) {
  //       // //   this.setState({
  //       // //     trainerNotes: snapshot.val(),
  //       // //   });

  //       // //   if(!this.state.isFirstFetch){
  //       // //     this.setState({
  //       // //       isFirstFetch: true,
  //       // //     })
  //       // //   }else{
  //       // //     this.setState({
  //       // //       isTrainerOnline: true
  //       // //     })
  //       // //   }
  //       // // }
  //     });
  // }
}

export default withFirebase(PersonalDetails);
