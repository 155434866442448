import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
// import Rows from './Rows';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
    firstName,
    lastName,
    phone,
    email,
    totalExpYears,
    totalExpMonths,
    USTotalExpYears,
    USTotalExpMonths,
    personalSummary
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu, Typography, Divider, Input } from 'antd';
import ResumeView from './ResumeView';

const { Title, Paragraph, Text } = Typography;

const RawHTML = ({ children, className = '' }) => (
    <div id="source-html"
        dangerouslySetInnerHTML={{
            __html: children.replace(/\n/g, '<br />'),
        }}
    />
);


class ResumeTemplate extends React.Component {

    state = {
        isPublic: false,
        isPublished: false,
        publicResumeId: "",
        isLoading: true,
    }

    componentDidMount() {
        this.props.firebase.onAuthUserListener(authUser => {
            database()
                .ref('users/' + authUser.uid + '/resume')
                .once('value')
                .then(snapshot => {
                    if (snapshot.val()) {
                        this.setState({
                            ...snapshot.val(),
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }
                });
        });
    }

    handlePublish = () => {
        this.props.firebase.onAuthUserListener(authUser => {

            const { publicResumeId } = this.state
            const { resumeAsHtml } = this.props.values
            database()
                .ref('public/' + publicResumeId)
                .once('value')
                .then(snapshot => {
                    var userProfile = snapshot.val()
                    if (userProfile) {
                        if (userProfile.userId === authUser.uid) {
                            database()
                                .ref('public/' + publicResumeId.toLowerCase())
                                .set({
                                    publicResumeId,
                                    userId: authUser.uid,
                                    resumeAsHtml,
                                }, () => {
                                    this.setState({
                                        isPublic: true,
                                    })
                                    alert('Success')
                                })
                        } else {
                            alert("User Name Already Taken")
                        }
                    } else {
                        database()
                            .ref('public/' + publicResumeId)
                            .set({
                                userId: authUser.uid,
                                publicResumeId,
                                resumeAsHtml,
                            }, () => {
                                this.setState({
                                    isPublic: true,
                                })
                                alert('Super, You resume is live at https://', publicResumeId, '.com')
                            })


                        database()
                            .ref('users/' + authUser.uid + '/resume')
                            .update({
                                publicResumeId,
                                isPublic: true,
                            })

                        // alert('You already have resume published at ', this.state.publicResumeId )
                    }
                });



        });
    }

    handleSave = () => {
        this.props.firebase.onAuthUserListener(authUser => {
            database()
                .ref('users/' + authUser.uid + '/resume')
                .update({
                    resume: this.props.resume
                }, () => {
                    alert('Success')
                })
        });
    }

    handleMakeItPublic = () => {
        this.props.firebase.onAuthUserListener(authUser => {
            database()
                .ref('users/' + authUser.uid + '/resume/isPublic')
                .set(true, () => {
                    database()
                        .ref('public/' + this.state.publicResumeId + '/isPublic')
                        .set(true, () => {
                            alert('Your resume is  public')
                        })
                })

            this.setState({
                isPublic: true,
                isPublished: true
            })

        });
    }

    handleUnPublish = () => {
        this.props.firebase.onAuthUserListener(authUser => {
            database()
                .ref('users/' + authUser.uid + '/resume')
                .update({
                    isPublic: false
                }, () => {
                    database()
                        .ref('public/' + this.state.publicResumeId)
                        .set({}, () => {
                            alert('Your resume is not public any more')
                            this.setState({
                                isPublic: false,
                            })
                        })
                })
        });
    }

    renderHeader = () => {
        const { isPublic, isPublished, publicResumeId } = this.state
        if (isPublic) {
            return (
                <div>
                    <span style={{margin: '10px'}}>Awesome !👍! Share this public link with recruiters <a href={`/r/${publicResumeId}`} target="_blank">{`https://linterviews/r/${publicResumeId}`}</a></span>
                    <Button onClick={this.handleUnPublish}>Un Publish</Button>
                </div>
            )
        }

        return (
            <div>
                <Row gutter={8}>
                    <Col md={12}>
                        <Paragraph>Create a personalized Unique URL below to publish your resume to share with others:</Paragraph>
                    </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col md={6}>
                            <Input value={publicResumeId} onChange={(e) => { this.setState({ publicResumeId: e.target.value.toLowerCase().replace(/\s/g, '-') }) }} />
                            <Text underline>{`www.linterviews.com/r/${publicResumeId}`}</Text>
                        </Col>
                        <Col md={6}>
                            <Button disabled={!publicResumeId} onClick={this.handlePublish}> Publish </Button>
                        </Col>
                    </Row>
            </div>
        )

    }

    render() {
        return (
            <Card loading={this.state.isLoading}>
                { this.renderHeader() }
            </Card>
        );
    }
}
export default withFirebase(ResumeTemplate);