import React from 'react';
import FormLabel from './FormLabel';
import FormField from './FormField';
import { get } from 'lodash';

import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Select,
  Radio,
  TimePicker,
} from 'antd';

import verbiage from '../../constants/verbage.json';

const InputMaker = ({
  id = 'notAvailable',
  label = get(verbiage, `${id}.fieldLabel`, ''),
  helpText = get(verbiage, `${id}.helpText`, ''),
  placeholder = get(verbiage, `${id}.placeHolder`, ''),
  isRequired = get(verbiage, `${id}.isRequired`, false),
  showField = get(verbiage, `${id}.showField`, true),
  error,
  required = false,
  isEditing = true,
  ...props
}) => {
  if (showField === false) {
    return null;
  }
  debugger;
  return (
    <Row>
      <Form.Item
        label={
          label && (
            <span>
              <span style={{ fontSize: '1.5em', marginLeft: '3px' }}>
                {label}
                {isRequired && '* '}
              </span>
              {helpText && (
                <Tooltip title={helpText}>
                  <Icon type="question-circle-o" />
                </Tooltip>
              )}
            </span>
          )
        }
      >
        {isEditing ? (
          <>
            <FormField id={id} placeholder={placeholder} {...props} />
            <span className="error-message">{error}</span>
          </>
        ) : (
          <>{props.value}</>
        )}
      </Form.Item>
    </Row>
  );
};

export default InputMaker;
