import React from 'react';
import _ from 'lodash';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import PreInterviewEdit from './PreInterviewEdit';
import PreInterviewView from './PreInterviewView';

import { Card, Col, Row, Button } from 'antd';
import getUrlParam from '../../utilities/getUrlParams';
import { Link } from 'react-router-dom';

class PreInterview extends React.Component {
  state = {
    file: null,
    interview: {},
    interviewDate: '',
    interviewFromTime: '',
    interviewToTime: '',
    timeZone: 'select',
    interviewDuration: 'lessThan30Mints',
    interviewMode: 'select',
    thingsToFocus: '',
    interviewNotes: '',
    personNameFromWhomYouGotThisInterview: '',
    linkToInterviewInvite: '',
    interviewers: [
      {
        name: '',
        profileLink: '',
      },
      {
        name: '',
        profileLink: '',
      },
      {
        name: '',
        profileLink: '',
      },
      {
        name: '',
        profileLink: '',
      },
      {
        name: '',
        profileLink: '',
      },
    ],

    isEditing: false,
    isLoading: true,
    isSaving: false,
    isNew: false,
    roundId: 'new',
  };

  handleSave = values => {
    this.setState({
      isSaving: true,
    });

    let roundId = getUrlParam('rid');
    let interviewId = getUrlParam('iid');

    if (roundId === 'new') {
      roundId = new Date().getTime();
    }

    this.props.firebase.onAuthUserListener(authUser => {
      delete values.isEditing;
      delete values.isSaving;
      delete values.isLoading;
      delete values.isNew;
      delete values.interview;
      values.roundId = roundId;
      values.interviewId = interviewId;

      database()
        .ref(
          'users/' +
            authUser.uid +
            `/interviews/${interviewId}/rounds/${roundId}/pre`,
        )
        .set(values, () => {
          this.setState({ ...values, isEditing: false, roundId });
        });
      this.props.history.replace(
        `/round?iid=${interviewId}&rid=${roundId}`,
      );
    });
  };

  handleDelete = () => {
    let roundId = getUrlParam('rid');
    let interviewId = getUrlParam('iid');

    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref(
          'users/' +
            authUser.uid +
            `/interviews/${interviewId}/rounds/${roundId}`,
        )
        .set({}, () => {
          this.props.history.replace(`/interview?iid=${interviewId}`);
        });
    });
  };

  componentDidMount() {
    const interviewId = getUrlParam('iid');
    const roundId = getUrlParam('rid');
    if (interviewId !== 'new') {
      this.props.firebase.onAuthUserListener(authUser => {
        database()
          .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
          .once('value')
          .then(snapshot => {
            const interview = snapshot.val();
            const round = _.get(
              interview,
              `rounds[${roundId}].pre`,
              {},
            );
            if (interview) {
              this.setState({
                // ...snapshot.val().rounds[roundId].pre ||  {},
                ...round,
                interview: snapshot.val() || {},
                isLoading: false,
                isNew: roundId === 'new',
              });
            }
          });
      });
    } else {
      this.setState({
        isLoading: false,
        // isEditing: true,
        isNew: true,
      });
    }
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };

  getRoundNavHeader = () => {
    const interviewId = getUrlParam('iid');
    const roundId = getUrlParam('rid');
    const {
      interview: { interviewClient },
      isLoading,
      roundNumber = 'New Round',
    } = this.state;
    if (isLoading) return null;
    return (
      <Card>
        <Row gutter={32}>
          <Col md={6} xs={6}>
            <Link to={`/interview/?iid=${interviewId}`}>
              <Button size={'large'} className="card-header__label">
                {interviewClient}
              </Button>
            </Link>
            <span className="card-header__label">></span>
            <span className="small-text">{roundNumber}</span>
          </Col>
          <Col md={13} xs={1}></Col>
          <Col md={3} xs={4}>
            {roundId !== 'new' && (
              <Link to={`/profile?iid=${interviewId}&rid=${roundId}`}>
                <Button>Interview Mode</Button>
              </Link>
            )}
          </Col>
          <Col md={2} xs={3}>
            {roundId !== 'new' ? (
              <Button onClick={this.handleDelete}>{`Delete`}</Button>
            ) : (
              <Link to={`/interview/?iid=${interviewId}`}>
                <Button className="card-header__label">Delete</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { isEditing, isLoading } = this.state;

    return (
      <Card loading={isLoading} title={this.getRoundNavHeader()}>
        {isEditing ? (
          <PreInterviewEdit
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            values={this.state}
          />
        ) : (
          <PreInterviewView
            handleEdit={this.handleEdit}
            values={this.state}
          />
        )}
      </Card>
    );
  }
}
export default withFirebase(PreInterview);
