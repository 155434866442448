import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  mastersUniversity,
  mastersStreet,
  mastersCity,
  mastersState,
  mastersZip,
  mastersStartDate,
  mastersEndDate,
  bachelorUniversity,
  bachelorStreet,
  bachelorCity,
  bachelorState,
  bachelorZip,
  bachelorStartDate,
  bachelorEndDate,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu } from 'antd';

class PersonalDetailsView extends React.Component {
  getFieldDisplayProps = (id, postText = '') => {
    return {
      value: this.props.values[id] + postText,
      id,
    };
  };

  getExperienceField = (yearsId, monthsId) => {
    return {
      value: `${this.props.values[yearsId]} Years ${this.props.values[monthsId]} Months`,
      id: yearsId,
    };
  };

  render() {
    return (
      <Card
        title={
          <span className="card-header__label">{'Education'}</span>
        }
        extra={
          <>
            <Button onClick={this.props.handleEdit}>Edit</Button>
          </>
        }
      >
        <Card
          className="add-shadow"
          title={
            <span className="card-header__label">{'Masters'}</span>
          }
        >
          <Row gutter={16}>
            <Col md={12}>
              <FieldDisplay
                {...this.getFieldDisplayProps(mastersUniversity)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(mastersStartDate)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(mastersEndDate)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(mastersStreet)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(mastersCity)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(mastersState)}
              />
            </Col>
          </Row>
        </Card>
        <Card
          className="add-shadow"
          title={
            <span className="card-header__label">{'Bachelors'}</span>
          }
          style={{marginTop: '20px'}}
        >
          <Row gutter={16}>
            <Col md={12}>
              <FieldDisplay
                {...this.getFieldDisplayProps(bachelorUniversity)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(bachelorStartDate)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(bachelorEndDate)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            {/* <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(bachelorStreet)}
              />
            </Col> */}
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(bachelorCity)}
              />
            </Col>
            <Col md={6}>
              <FieldDisplay
                {...this.getFieldDisplayProps(bachelorState)}
              />
            </Col>
          </Row>
        </Card>
        {/* <FieldDisplay {...this.getExperienceField(USTotalExpYears,USTotalExpMonths)} />
        <FieldDisplay {...this.getFieldDisplayProps(personalSummary)} /> */}
        {/* <FieldDisplay {...this.getFieldDisplayProps(USTotalExpYears)} />
        <FieldDisplay {...this.getFieldDisplayProps(USTotalExpMonths)} /> */}
      </Card>
    );
  }
}
export default withFirebase(PersonalDetailsView);
