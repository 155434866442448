import React from 'react';
import { database, storage } from 'firebase';
import InterviewMainDetails from '../Interview/interviewMainDetailsIndex';
import _ from 'lodash';

import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Select,
  Radio,
  TimePicker,
} from 'antd';
import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datepicker from '../Datepicker';
import Timepicker from '../TimePicker';
import { DropDownMenu } from 'material-ui';
import getUrlParam from '../../utilities/getUrlParams';
// import InterviewsGrid from './components/InterviewsGrid';
const { Option } = Select;

class InterviewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobType: 'contract',
      contractTerm: '',
      jobLocation: '',
      compensation: '',
      file: null,
      interviewClient: '', //Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      jobDescription: '',
      interviewDate: '',
      interviewTimeHour: '',
      interviewFromTime: '',
      interviewToTime: '',
      timeZone: 'SELECT',
      interviewDuration: 'lessThan30Mints',
      interviewMode: '',
      thingsToFocus: '',
      interviewNotes: '',
      vendorDetails: '',
      personNameFromWhomYouGotThisInterview: '',
      linkToInterviewInvite: '',
      personToContactIfInterviewDoesNotStart: '',
      positionType: '',
      isLoading: true,
      questionsToAsk: '',
      interviewers: [
        {
          name: '',
          profileLink: '',
        },
        {
          name: '',
          profileLink: '',
        },
        {
          name: '',
          profileLink: '',
        },
        {
          name: '',
          profileLink: '',
        },
        {
          name: '',
          profileLink: '',
        },
      ],
    };
  }

  componentDidMount() {
    const interviewId = getUrlParam('iid');
    if (interviewId !== 'new') {
      this.props.firebase.onAuthUserListener(authUser => {
        database()
          .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
          .once('value')
          .then(snapshot => {
            this.setState({
              ...(snapshot.val() || {}),
              isLoading: false,
            });
          });
        // debugger
        // const imagesRef = storage().ref(`${authUser.uid}/interviews`)
        // const storageRef = imagesRef.child(interviewId)
        // debugger
        // imagesRef.listAll().then(function(res) {
        //   res.prefixes.forEach(function(folderRef) {
        //     debugger
        //     // All the prefixes under listRef.
        //     // You may call listAll() recursively on them.
        //   });
        //   res.items.forEach(function(itemRef) {
        //     debugger
        //     // All the items under listRef.
        //   });
        // }).catch(function(error) {
        //   debugger
        //   // Uh-oh, an error occurred!
        // });
        // const image = images.child('image1');
        // image.getDownloadURL().then((url) => { this.setState({ img: url }));
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  // saveToFirebase = authUser => {
  //   const values = this.state;
  //   const userId = authUser.uid;
  //   database()
  //     .ref('users/' + userId + '/interviewDetails/')
  //     .set(values);
  // };

  handleAddInterview = () => {
    const interviewId = new Date().getTime();
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
        .set(this.state, () => {
          this.props.history.push('/interviews');
        });
    });
  };

  handleSaveInterview = () => {
    const interviewId = getUrlParam('iid');
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
        .set(this.state, () => {
          this.props.history.push('/interviews');
        });
    });
  };

  handleDelete = () => {
    const interviewId = getUrlParam('iid');
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
        .remove()
        .then(() => {
          this.props.history.push('/interviews');
        });
    });
  };

  handleCancel = () => {
    this.props.history.push('/interviews');
  };

  handleChange = ({ id: key, value }) => {
    const newState = _.set(this.state, key, value);
    this.setState(newState);
  };

  handleJobTypeChange = e => {
    this.setState({
      jobType: e.target.value,
    });
  };

  handleFileChoose = e => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      this.setState({
        file,
      });
    }
  };

  handleUploadFiles = e => {
    const file = this.state.file;
    if (file) {
      const interviewId = getUrlParam('iid');
      this.props.firebase.onAuthUserListener(authUser => {
        var storageRef = storage().ref(
          `${authUser.uid}/interviews/${interviewId}/${file.name}+${file.lastModified}`,
        );
        storageRef.put(file).then(function() {
          alert('File Uploaded');
        });
        e.preventDefault();
      });
    }
  };

  render() {
    const interviewId = getUrlParam('iid');
    let actionsToRender = null;
    let headerToRender = null;
    if (interviewId === 'new') {
      actionsToRender = (
        <>
          <Button onClick={this.handleAddInterview}>Add</Button>
          <Button onClick={this.handleCancel}>Cancel</Button>
        </>
      );
      headerToRender = <h3>Adding upcoming interview</h3>;
    } else {
      actionsToRender = (
        <>
          <Button onClick={this.handleSaveInterview}>Save</Button>
          <Button className="btn-delete" onClick={this.handleDelete}>Delete</Button>
        </>
      );
      headerToRender = <h3>Updating interview</h3>;
    }
    return (
      <div>
        {/* <Radio.Group onChange={this.handleJobTypeChange} id="jobType" value={this.state.jobType} style={{ marginBottom: 8 }}>
            <Radio.Button value="fullTime">Full Time</Radio.Button>
            <Radio.Button value="contract">Contracting</Radio.Button>
          </Radio.Group>
          <Card>
            <form>
              <Input accept="image/*" type="file" id="uploadZip" name="icon" onChange={this.handleFileChoose}/>
              <Button onClick={this.handleUploadFiles}>Upload File</Button>
            </form>
          </Card> */}
        <InterviewMainDetails {...this.props}/>
        {/* <PreInterview interview={this.state} onChange={this.handleChange.bind(this)}/>
          <DuringInterview interview={this.state} onChange={this.handleChange.bind(this)}/>
          <PostInterview interview={this.state} onChange={this.handleChange.bind(this)}/> */}
      </div>
    );
  }
}
export default withFirebase(InterviewDetails);
