import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import {
  Form,
  Icon,
  Input,
  Col,
  Row,
  Card,
  Tooltip,
  Button,
} from 'antd';
import Firebase, { withFirebase } from './components/Firebase';
import { Link } from 'react-router-dom';
import StatusIcon from './components/StatusIcon';
import takeToLogin from './TakeToLogin';

const VisaCard = ({type, startDate, endDate}) => {
  return(
    <Card 
      size={"small"} 
      style={{margin: '10px'}} 
      title={<h4>{type}</h4>}
      >
      <div>
        <h6>Start</h6>
      </div>
      {startDate}
      <div>
        <h6>End</h6>
      </div>
      {endDate}
    </Card>
  )
}

class VisaViewer extends React.Component {

  render() {
    const { visa } = this.props
    const {
      GreenCardStartDate, 
      h1bEndDate, 
      h1bExtEndDate, 
      h1bExtStartDate, 
      h1bStartDate, 
      cptEndDate, 
      cptStartDate, 
      currentVisa, 
      isCitizen, 
      isCpt, 
      isGreenCard, 
      isH1b, 
      isH1bExt, 
      isOpt, 
      isOptExt, 
      isSecondMasters, 
      optEndDate, 
      optExtEndDate, 
      optExtStartDate, 
      optStartDate, 
      currentVisaValidUntil,
      secondMastersStateDate,
      secondMastersEndDate
     } = visa
    return (
     <Card 
      style={{width:'100%'}}
      title={`Visa : ${currentVisa} | Validity: ${currentVisaValidUntil}`}
      extra={
        <Link to="/visa">
          <Icon type="edit"/>
        </Link>}
      >
       <div style={{display: 'flex'}}>
        {/* {isGreenCard && <VisaCard startDate={GreenCardStartDate} endDate={'Never'} type={'Green Card'}/>} */}
        {isSecondMasters && <VisaCard startDate={secondMastersStateDate} endDate={secondMastersEndDate} type={'Second Masters'}/>}
        {isH1bExt && <VisaCard startDate={h1bExtStartDate} endDate={h1bExtEndDate} type={'H1b Ext'}/>}
        {isH1b && <VisaCard startDate={h1bStartDate} endDate={h1bEndDate} type={'H1b'}/>}
        {isOpt && <VisaCard startDate={optExtStartDate} endDate={optExtEndDate} type={'OPT Ext'}/>}
        {isOpt && <VisaCard startDate={optStartDate} endDate={optEndDate} type={'OPT'}/>}
        {isCpt && <VisaCard startDate={cptStartDate} endDate={cptEndDate} type={'CPT'}/>}
       </div>
     </Card>
    );
  }
}

export default VisaViewer
