import React from 'react';
import { Icon, DatePicker, Tooltip, Select, Slider, Row, Col } from 'antd';

function Slido({ value, onChange, id, sliderText="", ...props}) {

  value = Number(value)
  
  return (
    <Row gutter={16}>
      <Col md={16}>
        <Slider
          {...props}
          id={id}
          // defaultValue="select"
            value={value}
            onChange={(e)=>{
              onChange({target: {value: e, id}})
            }}
          />
        </Col>
        <Col md={8}>
          <h3>{value} {sliderText}</h3>
        </Col>
      </Row>
  );
}

export default Slido;
