import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import {
  Card,
  Icon
} from 'antd'
import { Link } from 'react-router-dom';

// const HomePage = () => (
//   <Card style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
//     <h3>We at linterviews Love to have You, just one more step, before we can dine together....</h3>
//     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
//       <Icon style={{ fontSize: '50px' }} type="mail" theme="twoTone" />
//       <h2>E-Mail confirmation sent</h2>
//       <h3> Check your E-Mails (Spam
//                       folder included) for a confirmation E-Mail.
//                     {/* <Link to="/personalDetails">{` Click here `}</Link> */}
//         once you confirmed your E-Mail.</h3>
//     </div>

//     {/* <h2>Let's verify if it's you !!</h2>
//     <h3>Click on the email link sent to your email address to access the portal.</h3> */}
//   </Card>
// );

const HomePage = () => (
  <Card>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>  
      <Icon style={{ fontSize: '50px' }} type="mail" theme="twoTone" />
      <h2>E-Mail confirmation sent</h2>
      {/* <h4>We at linterviews Love to have You, just one more step, before we can dine together....</h4> */}
      <h6 style={{textAlign: 'center'}}> Check your E-Mails (Spam
folder included) for a confirmation E-Mail.</h6>
      <h3 style={{textAlign: 'center'}}>Sign in back once you confirmed your E-Mail</h3>
    </div>
  </Card>
);

//const condition = authUser => !!authUser;

export default HomePage

// export default compose(
//   withEmailVerification,
//   withAuthorization(condition),
// )(HomePage);
