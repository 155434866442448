import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  currentVisa,
  currentVisaValidUntil,
  isCpt,
  cptStartDate,
  cptEndDate,
  isOpt,
  optStartDate,
  optEndDate,
  isOptExt,
  optExtStartDate,
  optExtEndDate,
  isH1b,
  h1bStartDate,
  h1bEndDate,
  isH1bExt,
  h1bExtStartDate,
  h1bExtEndDate,
  isSecondMasters,
  secondMastersStateDate,
  secondMastersEndDate,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu } from 'antd';

class VisaView extends React.Component {
  getFieldDisplayProps = (id, postText = '') => {
    let value = this.props.values[id]
    if(typeof value === "boolean"){
      value = value ? "Yes" : "No"
    }

    return {
      value,
      id,
    };
  };

  getExperienceField = (yearsId, monthsId) => {
    return {
      value: `${this.props.values[yearsId]} Years ${this.props.values[monthsId]} Months`,
      id: yearsId,
    };
  };

  renderRows(firstCol, SecondCol, thirdCol) {
    return (
      <Row gutter={16}>
        <Col md={4}>
          <FieldDisplay {...this.getFieldDisplayProps(firstCol)} />
        </Col>
        <Col md={4}>
          {this.props.values[firstCol] && <FieldDisplay {...this.getFieldDisplayProps(SecondCol)} /> }
        </Col>
        <Col md={4}>
        {this.props.values[firstCol] && <FieldDisplay {...this.getFieldDisplayProps(thirdCol)} /> }
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Card
        title={
          <span className="card-header__label">{'Visa'}</span>
        }
        extra={
          <>
            <Button onClick={this.props.handleEdit}>Edit</Button>
          </>
        }
      >
        <Row gutter={16}>
          <Col md={12}>
            <FieldDisplay {...this.getFieldDisplayProps(currentVisa)} />
          </Col>
          <Col md={6}>
            <FieldDisplay {...this.getFieldDisplayProps(currentVisaValidUntil)} />
          </Col>
        </Row>
        {this.renderRows(isCpt, cptStartDate, cptEndDate)}
        {this.renderRows(isOpt, optStartDate, optEndDate)}
        {this.renderRows(isOptExt, optExtStartDate, optExtEndDate)}
        {this.renderRows(isH1b, h1bStartDate, h1bEndDate)}
        {this.renderRows(isH1bExt, h1bExtStartDate, h1bExtEndDate)}
        {this.renderRows(
          isSecondMasters,
          secondMastersStateDate,
          secondMastersEndDate,
        )}
      </Card>
    );
  }
}
export default withFirebase(VisaView);
