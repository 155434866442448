import React from 'react';
import { Icon, TimePicker, Tooltip } from 'antd';
import moment from 'moment';

import verbiage from '../constants/verbage.json'

function Timepicker({ value, onChange, id }){
  return (
    <>
    <TimePicker
      use12Hours
      minuteStep={15}
      format={"h:mm a"}
      id={id}
      { ...(value ? {value: moment.unix(value)} : null)}
      onChange={e =>
        e
          ? onChange({
              target: {
                value: e.unix(),
                id
              },
            })
          : onChange({
              target: {
                value: null,
                id
              },
            })
      }
    />
    </>
  );
};

export default Timepicker
