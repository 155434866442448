
import React from 'react';
import { withFirebase } from '../Firebase';
import { Editor } from 'react-draft-wysiwyg';

import { Card, Col, Row, Button } from 'antd';
import { EditorState, convertFromRaw } from 'draft-js';

class ResumeEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount(){
    const { values : { resumeAsJsonForEditor }} = this.props
    if(resumeAsJsonForEditor){
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(resumeAsJsonForEditor)
        ))
      })
    }
  }

  handleSave = () => {
    this.props.handleSave(this.state.editorState)
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
  };

  render() {
    const {
      editorState,
      isLoading,
      htmlMarkUp,
      isEditing,
    } = this.state;

    return (
      <div>
        <Row gutter={8}>
          <Col md={20} sm={5} xs={5} />
          <Col md={2} style={{ textAlign: 'right' }}>
            <Button onClick={this.handleSave}>Save</Button>
          </Col>
          <Col md={2}>
            <Button onClick={this.props.handleCancel}>Cancel</Button>
          </Col>
        </Row>
        <div className="editor">
          <Editor
            editorState={editorState}
            style={{ height: '100%', width: '100%' }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>
      </div>
    );
  }
}
export default withFirebase(ResumeEdit);
