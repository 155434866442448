import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import InputMaker from '../Form';
import { withFirebase } from '../Firebase';

import {
  firstName,
  lastName,
  phone,
  email,
  totalExpYears,
  totalExpMonths,
  USTotalExpYears,
  USTotalExpMonths,
  personalSummary,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Slider, Modal } from 'antd';

class PersonalDetailsEdit extends React.Component {
  render() {
    return (
      <>
        <Formik
          initialValues={this.props.values}
          validateOnBlur
          // enableReinitialize
          //validationSchema={InterviewHeaderSchema}
        >
          {props => {
            const {
              values,
              handleChange,
              handleBlur,
              errors,
              setErrors,
            } = props;

            //const isValid = InterviewHeaderSchema.isValidSync(values);

            const getInputProps = id => ({
              id,
              value: values[id],
              error: errors[id],
              onChange: handleChange,
              onBlur: handleBlur,
            });

            const handleSave = () => this.props.handleSave(values);

            return (
              <Modal
                confirmLoading={this.props.values.isSaving}
                // title="Basic Modal"
                // closeIcon={<div></div>}
                visible={this.props.values.isEditing}
                onOk={handleSave}
                okText={'Save'}
                onCancel={this.props.handleCancel}
                // footer={null}
                width={'80%'}
              >
                <Card
                  // extra={
                  //   this.props.values.isNew ?
                  //     <Row gutter={16}>
                  //       <Col md={12}>
                  //         <Button onClick={handleSave} > {/** disabled={!isValid} */}
                  //           Add
                  //         </Button>
                  //       </Col>
                  //     </Row> :
                  //     <Row gutter={16}>
                  //       <Col md={12}>
                  //         <Button onClick={handleSave} type="primary">
                  //           Save
                  //         </Button>
                  //       </Col>
                  //       <Col md={12}>
                  //         <Button onClick={this.props.handleCancel}>
                  //           Cancel
                  //         </Button>
                  //       </Col>
                  //     </Row>

                  // }
                  title={'Editing Details'}
                >
                  <Row gutter={16}>
                    <Col md={6}>
                      <InputMaker
                        id={firstName}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[firstName]}
                        error={errors[firstName]}
                      />
                    </Col>
                    <Col md={6}>
                      <InputMaker
                        id={lastName}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[lastName]}
                        error={errors[lastName]}
                      />
                    </Col>
                    <Col md={6}>
                      <InputMaker
                        id={phone}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[phone]}
                        error={errors[phone]}
                      />
                    </Col>
                    <Col md={6}>
                      <InputMaker
                        id={email}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[email]}
                        error={errors[email]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={12}>
                      {/* <Slider
                      min={1}
                      max={20}
                      //onChange={this.onChange}
                      //value={typeof inputValue === 'number' ? inputValue : 0}
                    /> */}
                      <InputMaker
                        id={totalExpYears}
                        required
                        min={1}
                        max={10}
                        type="slider"
                        sliderText="Years"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[totalExpYears]}
                        error={errors[totalExpYears]}
                      />
                      <InputMaker
                        id={totalExpMonths}
                        required
                        min={1}
                        max={11}
                        type="slider"
                        sliderText="Months"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[totalExpMonths]}
                        error={errors[totalExpMonths]}
                      />
                    </Col>
                    <Col md={12}>
                      <InputMaker
                        id={USTotalExpYears}
                        required
                        min={1}
                        max={10}
                        sliderText="Years"
                        type="slider"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[USTotalExpYears]}
                        error={errors[USTotalExpYears]}
                      />
                      <InputMaker
                        id={USTotalExpMonths}
                        required
                        min={1}
                        max={11}
                        type="slider"
                        sliderText="Months"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[USTotalExpMonths]}
                        error={errors[USTotalExpMonths]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <InputMaker
                      id={personalSummary}
                      required
                      type="textArea"
                      rows={50}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[personalSummary]}
                      error={errors[personalSummary]}
                    />
                  </Row>
                </Card>
              </Modal>
            );
          }}
        </Formik>
      </>
    );
  }
}
export default withFirebase(PersonalDetailsEdit);
