import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import InputMaker from '../Form';
import { withFirebase } from '../Firebase';

import {
  mastersUniversity,
  mastersStreet,
  mastersCity,
  mastersState,
  mastersZip,
  mastersStartDate,
  mastersEndDate,
  bachelorUniversity,
  bachelorStreet,
  bachelorCity,
  bachelorState,
  bachelorZip,
  bachelorStartDate,
  bachelorEndDate,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Slider } from 'antd';

class PreInterviewEdit extends React.Component {
  render() {
    return (
      <>
        <Formik
          initialValues={this.props.values}
          validateOnBlur
          //validationSchema={InterviewHeaderSchema}
        >
          {props => {
            const {
              values,
              handleChange,
              handleBlur,
              errors,
              setErrors,
            } = props;

            //const isValid = InterviewHeaderSchema.isValidSync(values);

            const getInputProps = id => ({
              id,
              value: values[id],
              error: errors[id],
              onChange: handleChange,
              onBlur: handleBlur,
            });

            const handleSave = () => this.props.handleSave(values);

            return (
              <>
                <Card
                  extra={
                    <Row gutter={16}>
                      <Col md={12}>
                        <Button onClick={handleSave} type="primary">
                          Save
                        </Button>
                      </Col>
                      <Col md={12}>
                        <Button onClick={this.props.handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  }
                  title={
                    <span className="card-header__label">
                      {'Education'}
                    </span>
                  }
                >
                  <Card
                    className="add-shadow"
                    title={
                      <span className="card-header__label">
                        {'Masters'}
                      </span>
                    }
                  >
                    <Row gutter={16}>
                      <Col md={12}>
                        <InputMaker
                          {...getInputProps(mastersUniversity)}
                        />
                      </Col>
                      <Col md={6}>
                        <InputMaker
                          type="date"
                          {...getInputProps(mastersStartDate)}
                        />
                      </Col>
                      <Col md={6}>
                        <InputMaker
                          type="date"
                          {...getInputProps(mastersEndDate)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col md={6}>
                        <InputMaker
                          {...getInputProps(mastersStreet)}
                        />
                      </Col>
                      <Col md={6}>
                        <InputMaker {...getInputProps(mastersCity)} />
                      </Col>
                      <Col md={6}>
                        <InputMaker
                          {...getInputProps(mastersState)}
                        />
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    className="add-shadow"
                    title={
                      <span className="card-header__label">
                        {'Bachelors'}
                      </span>
                    }
                    style={{ marginTop: '20px' }}
                  >
                    <Row gutter={16}>
                      <Col md={12}>
                        <InputMaker
                          {...getInputProps(bachelorUniversity)}
                        />
                      </Col>
                      <Col md={6}>
                        <InputMaker
                          type="date"
                          {...getInputProps(bachelorStartDate)}
                        />
                      </Col>
                      <Col md={6}>
                        <InputMaker
                          type="date"
                          {...getInputProps(bachelorEndDate)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      {/* <Col md={6}>
    <InputMaker
      {...getInputProps(bachelorStreet)}
    />
  </Col> */}
                      <Col md={6}>
                        <InputMaker
                          {...getInputProps(bachelorCity)}
                        />
                      </Col>
                      <Col md={6}>
                        <InputMaker
                          {...getInputProps(bachelorState)}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Card>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
export default withFirebase(PreInterviewEdit);
