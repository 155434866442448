import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import { withFirebase } from './components/Firebase';
import { AuthUserContext } from './components/Session';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Dropdown,
  Menu,
  Button,
  DatePicker,
  Checkbox,
  Typography,
  Anchor,
} from 'antd';
import moment from 'moment';
const { Text, Link } = Typography;

class Project extends React.Component {
  state = {
    isEnabled: true,
    clientName: '',
    startDate: '',
    endDate: '',
    projectName: '',

    homeStreet: '',
    homeCity: '',
    homeState: '',
    homeZipCode: '',

    aboutProject: '',
    notes: '',
    trainerNotes: '',
    visaStatusAtTimeOfProject: '',
    visaNotes: '',
    isLoading: true,
  };

  handleProjectToggle = () => {
    this.setState({
      isEnabled: !this.state.isEnabled,
    });
  };

  componentDidMount() {
    const projectId = this.props.projectId;
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/projects/' + projectId)
        .once('value')
        .then(snapshot => {
          this.setState({
            ...(snapshot.val() || {}),
            isLoading: false,
          });
        });
    });
  }

  saveToFirebase = authUser => {
    const values = this.state;
    const userId = authUser.uid;
    const projectId = this.props.projectId;
    database()
      .ref('users/' + userId + '/projects/' + projectId)
      .set(values);
  };

  render() {
    let { isEnabled } = this.state;
    isEnabled = true//isEnabled == 'true';
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Card loading={this.state.isLoading}>
            <Formik
              initialValues={this.state}
              validateOnBlur
              //validationSchema={projectSchema}
              enableReinitialize
            >
              {props => {
                const {
                  values,
                  // dirty,
                  // isSubmitting,
                  handleSubmit,
                  handleReset,
                  handleChange,
                  handleBlur,
                  errors,
                  // validateOnBlur,
                  // touched,
                } = props;

                const UpdateForm = e => {
                  handleChange(e);
                  const {
                    target: { id, value },
                  } = e;
                  this.setState(
                    {
                      [id]: value,
                    },
                    () => {
                      this.saveToFirebase(authUser);
                    },
                  );
                };

                const UpdateDate = e => {
                  handleChange(e);
                  const {
                    target: { id, value },
                  } = e;
                  this.setState(
                    {
                      [id]: value || '',
                    },
                    () => {
                      this.saveToFirebase(authUser);
                    },
                  );
                };
                const { startDate, endDate } = this.state;
                return (
                  <>
                    {/* <Button
                      id={'isEnabled'}
                      name={'isEnabled'}
                      size="medium"
                      type="link"
                      ghost
                      onClick={UpdateForm}
                      value={!isEnabled}
                    >
                      {isEnabled ? 'Show' : 'Hide'}
                    </Button> */}
                    {/* <Text>
                      {`  This will show/hide the project from your`}
                      <a href="/profile" target="_blank">
                        {' '}
                        profile
                      </a>
                    </Text> */}
                    <Form onSubmit={handleSubmit}>
                      <Card>
                        <Row md={3}>
                          <Form.Item
                            label={
                              <span>
                                Client Name&nbsp;
                                <Tooltip title="Seriously ?? 😃">
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                            }
                          >
                            <Field
                              disabled={!isEnabled}
                              component={Input}
                              id={'clientName'}
                              name={'clientName'}
                              placeholder={'Super Corp'}
                              type={'text'}
                              onChange={UpdateForm}
                              onBlur={handleBlur}
                              value={values['clientName']}
                              label={'label'}
                            />
                            <span className="error-message">
                              {errors['clientName']}
                            </span>
                          </Form.Item>
                        </Row>

                        <Row md={3}>
                          <Form.Item
                            label={
                              <span>
                                Project Name&nbsp;
                                <Tooltip title="Seriously ?? 😃">
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                            }
                          >
                            <Field
                              disabled={!isEnabled}
                              component={Input}
                              id={'projectName'}
                              name={'projectName'}
                              placeholder={'SmartCity Dashboard'}
                              type={'text'}
                              onChange={UpdateForm}
                              onBlur={handleBlur}
                              value={values['projectName']}
                              label={'label'}
                            />
                            <span className="error-message">
                              {errors['projectName']}
                            </span>
                          </Form.Item>
                        </Row>
                        <Row gutter={16}>
                          <Col md={6}>
                            <Form.Item
                              label={
                                <span>
                                  Start Date&nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <DatePicker
                                disabled={!isEnabled}
                                id="startDate"
                                value={moment(startDate) || startDate}
                                onChange={e =>
                                  e
                                    ? UpdateDate({
                                        target: {
                                          value: e.format(
                                            'MMM DD YYYY',
                                          ),
                                          id: 'startDate',
                                        },
                                      })
                                    : UpdateDate({
                                        target: {
                                          value: null,
                                          id: 'startDate',
                                        },
                                      })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item
                              label={
                                <span>
                                  End Date&nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <DatePicker
                                disabled={!isEnabled}
                                id="endDate"
                                value={moment(endDate) || endDate}
                                onChange={e =>
                                  e
                                    ? UpdateDate({
                                        target: {
                                          value: e.format(
                                            'MMM DD YYYY',
                                          ),
                                          id: 'endDate',
                                        },
                                      })
                                    : UpdateDate({
                                        target: {
                                          value: null,
                                          id: 'endDate',
                                        },
                                      })
                                }
                              />
                              {this.props.current && (
                                <div>
                                  Choose today's date if you are in
                                  project
                                </div>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Company Address &nbsp;
                                  <Tooltip title="Like 1234 Long Drive">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'clientStreet'}
                                name={'clientStreet'}
                                placeholder={'1234 Long Drive'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['clientStreet']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['clientStreet']}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  City &nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'clientCity'}
                                name={'clientCity'}
                                placeholder={'Columbus'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['clientCity']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['clientCity']}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  State &nbsp;
                                  <Tooltip title="Fun Fact: There are 3 states of Matter">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'clientState'}
                                name={'clientState'}
                                placeholder={'Arizona'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['clientState']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['clientState']}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Zip &nbsp;
                                  <Tooltip title="OMG !! Are you from different world ?">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'clientZipCode'}
                                name={'clientZipCode'}
                                placeholder={'12345'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['clientZipCode']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['clientZipCode']}
                              </span>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Home Address &nbsp;
                                  <Tooltip title="Where were you staying while working for this client">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'homeStreet'}
                                name={'homeStreet'}
                                placeholder={'1234 Short Drive'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['homeStreet']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['homeStreet']}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  City &nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'homeCity'}
                                name={'homeCity'}
                                placeholder={'Super Corp'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['homeCity']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['homeCity']}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  State &nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'homeState'}
                                name={'homeState'}
                                placeholder={'Super Corp'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['homeState']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['homeState']}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Zip &nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input}
                                id={'homeZipCode'}
                                name={'homeZipCode'}
                                placeholder={'Super Corp'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['homeZipCode']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['homeZipCode']}
                              </span>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <Form.Item
                              label={
                                <span>
                                  About Project(optional) &nbsp;
                                  <Tooltip title="Write short notes about your project if you like">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input.TextArea}
                                rows={4}
                                id={'aboutProject'}
                                name={'aboutProject'}
                                placeholder={
                                  'I worked on internal project'
                                }
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['aboutProject']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['aboutProject']}
                              </span>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <Form.Item
                              label={
                                <span>
                                  Visa Notes(your status during this
                                  project) &nbsp;
                                  <Tooltip title="Current Status is: h1b, I started with this company with OPT on Feb 2019 and got H1b from March 2019">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input.TextArea}
                                rows={4}
                                id={'visaStatusAtTimeOfProject'}
                                name={'visaStatusAtTimeOfProject'}
                                placeholder={
                                  'Current Status is: h1b \nI started with this company with OPT on Feb 2019 and got H1b from March 2019'
                                }
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={
                                  values['visaStatusAtTimeOfProject']
                                }
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['visaStatusAtTimeOfProject']}
                              </span>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <Form.Item
                              label={
                                <span>
                                  Trainer Notes: &nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                disabled={!isEnabled}
                                component={Input.TextArea}
                                //disabled
                                rows={4}
                                id={'trainerNotes'}
                                name={'trainerNotes'}
                                placeholder={
                                  'This is for Trainer, you can leave this blank'
                                }
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['trainerNotes']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['trainerNotes']}
                              </span>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Card>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
export default withFirebase(Project);
