import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  jobTitle,
  jobLocation,
  jobDescription,
  referredBy,
  salary,
  interviewClient,
  implementationPartner,
  vendorDetails,
  readyToJoinIn,
  interviewMainNotes,
  aboutCompany
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button , Modal} from 'antd';

const InterviewHeaderSchema = Yup.object().shape({
  [interviewClient]: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  [jobTitle]: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  [jobDescription]: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
});

class InterviewMainDetails extends React.Component {

  handleSave = (values) => {
    this.props.handleSave(values)
  };

  render() {
    return (
      <>
        <Formik
          initialValues={this.props.values}
          //validateOnBlur
          validationSchema={InterviewHeaderSchema}
          //enableReinitialize
        >
          {props => {
            const {
              values,
              handleChange,
              handleBlur,
              errors,
              setErrors,
              isValid,
              handleSubmit
            } = props;


            //const isValid = InterviewHeaderSchema.isValidSync(values);

            const getInputProps = id => ({
              id,
              value: values[id],
              error: errors[id],
              onChange: handleChange,
             // onBlur: handleBlur,
            })

            const handleSave = () =>{
              if(isValid){
                this.handleSave(values)
              }else{
                handleSubmit()
              }
            }

            return (
              <Modal
              confirmLoading={this.props.values.isSaving}
              // title="Basic Modal"
              // closeIcon={<div></div>}
              visible={this.props.values.isEditing}
              onOk={handleSave}
              okText={'Save'}
              onCancel={this.props.handleCancel}
              // footer={null}
              width={'80%'}
            >
                <Card
                 title={`Editing Interview Details`}
                  // extra={
                  //   this.props.values.isNew ?
                  //     <Row gutter={16}>
                  //       <Col md={12}>
                  //         <Button onClick={handleSave}>
                  //           Add
                  //         </Button>
                  //       </Col>
                  //     </Row> :
                  //     <Row gutter={16}>
                  //       <Col md={12}>
                  //         <Button onClick={handleSave}>
                  //           Save
                  //         </Button>
                  //       </Col>
                  //       <Col md={12}>
                  //         <Button onClick={this.props.handleCancel}>
                  //           Cancel
                  //         </Button>
                  //       </Col>
                  //     </Row>

                  // }
                >
                  <Row gutter={16}>
                    <Col md={6}>
                      <InputMaker {...getInputProps(interviewClient)} />
                    </Col>
                    <Col md={6}>
                      <InputMaker {...getInputProps(jobTitle)} />
                    </Col>
                    <Col md={6}>
                      <InputMaker {...getInputProps(jobLocation)} />
                    </Col>
                    <Col md={6}>
                      <InputMaker {...getInputProps(salary)}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={12}>
                      <InputMaker type="textArea" rows={5} {...getInputProps(implementationPartner)} />
                    </Col>
                    <Col md={12}>
                      <InputMaker type="textArea" rows={5} {...getInputProps(vendorDetails)} />
                    </Col>
                  </Row>
                   
                  <Row>
                    <InputMaker type='textArea' rows={5} {...getInputProps(jobDescription)} />
                  </Row>
                  <Row gutter={16}>
                    <Col md={12}>
                      <InputMaker {...getInputProps(readyToJoinIn)} />
                    </Col>
                    <Col md={12}>
                      <InputMaker {...getInputProps(referredBy)} />
                    </Col>
                  </Row>
                  <Row>
                    <InputMaker type='textArea' rows={5} {...getInputProps(interviewMainNotes)} />
                  </Row>
                  <Row>
                    <InputMaker type='textArea' rows={5} {...getInputProps(aboutCompany)} />
                  </Row>
                </Card>
              </Modal>
            );
          }}
        </Formik>
      </>
    );
  }
}
export default withFirebase(InterviewMainDetails);
