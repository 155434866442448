import React from 'react';
import _ from 'lodash';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import EducationEdit from './EducationEdit';
import EducationView from './EducationView';

import { Card, Col, Row, Button } from 'antd';

import {
  mastersUniversity,
  mastersStreet,
  mastersCity,
  mastersState,
  mastersZip,
  mastersStartDate,
  mastersEndDate,

  bachelorUniversity,
  bachelorStreet,
  bachelorCity,
  bachelorState,
  bachelorZip,
  bachelorStartDate,
  bachelorEndDate
} from '../../constants/fieldIds.json';

class EducationIndex extends React.Component {
  state = {
    isMasters: false,
    [mastersUniversity]: '',
    [mastersStreet]: '',
    [mastersCity]: '',
    [mastersState]: '',
    [mastersZip]: '',
    [mastersStartDate]: '',
    [mastersEndDate]: '',

    [bachelorUniversity]: '',
    [bachelorStreet]: '',
    [bachelorCity]: '',
    [bachelorState]: '',
    [bachelorZip]: '',
    [bachelorStartDate]: '',
    [bachelorEndDate]: '',

    isEditing: false,
    isLoading: true,
    isSaving: false,
    isNew: false
  };

  handleSave = (values) => {

    this.setState({
      isSaving: true,
    });

    this.props.firebase.onAuthUserListener(authUser => {
      delete values.isEditing
      delete values.isSaving
      delete values.isLoading

      database()
        .ref('users/' + authUser.uid + `/education/`)
        .set(values, () => {
          this.setState({...values, isEditing: false });
        });
    });
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/education/`)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            this.setState({
              ...snapshot.val() ||  {},
              isLoading: false,
              isNew: false
            });
          }else{
            this.setState({
              isLoading: false,
              isNew: 'new'
            })
          }

          })
    });
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };


  render() {
    const { isEditing, isLoading } = this.state;

    return (
      <Card loading={isLoading}>
        {
            isEditing ? 
            <EducationEdit
                handleSave={this.handleSave} 
                handleCancel={this.handleCancel}
                values={this.state}
            /> :
            <EducationView 
                handleEdit={this.handleEdit}
                values={this.state}
            />
        }
      </Card>
    );
  }
}
export default withFirebase(EducationIndex);
