import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import Home from '../Home';
import EmailNotVerified from '../EmailNotVerified';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Input, Button, Card, Row, Col } from 'antd';
import { get } from 'lodash'
import getUrlParam from '../../utilities/getUrlParams';

const SignInPage = () => (
  <div>
    <SignInForm />
    {/* <SignInGoogle />
    <SignInFacebook />
    <SignInTwitter /> */}
  </div>
);

// const INITIAL_STATE = {
//   email: '',//'belvikram@gmail.com',
//   password: '',//'//Verizon7',
//   error: null,
//   // {
//   //   message: "There is no user record corresponding to this identifier. The user may have been deleted."
//   // },
// };

const INITIAL_STATE = {
  email: '',//'belvikram@gmail.com',
  password: '',//,'Verizon7',
  error: null,
  // {
  //   message: "There is no user record corresponding to this identifier. The user may have been deleted."
  // },
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  // TODO: fix showing login page even after user logged in
  // componentDidMount(){
    
  //   this.props.firebase.onAuthUserListener(authUser => {
  //     debugger
  //     if(authUser.uid){
  //       this.props.history.push('/personalDetails')
  //     }
  //   });
  // }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(auth => {
        if(auth.user.emailVerified){
          const redirectURL = getUrlParam('redirect')
          
          // if(redirectURL){
          //   this.props.history.push(`/${redirectURL}`);
          // }else{
            this.props.history.push('/personalDetails');
         // }
         
        }else{
          this.props.history.push('/verify');
        }
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount(){
    this.setState({
      email: get(this,'props.location.props.email','')
    })
    // this.props.firebase.onAuthUserListener(authUser => {
    //   // if(authUser.uid){
        
    //   // }
    // },()=>{
    //   this.props.history.push('/personalDetails')
    // });
  }

  render() {
    // this.props.firebase.onAuthUserListener(authUser => {
    //   if(authUser.uid){
    //     this.props.history.push('/personalDetails')
    //   }
    // });
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';
    const previousScreen = get(this,'props.location.props.previousScreen')
    return (
      <Card style={{padding: '20px', display: 'flex',justifyContent: 'center', height: '100vh', backgroundColor:'#eee '}} className="add-shadow">
        <Row style={{display: 'flex',justifyContent: 'center',marginTop: '10px',flexDirection:'column',alignItems:'center'}}>
          <span style={{fontSize:'50px',fontWeight: '800', marginBottom:'5vh',marginTop:'5vh', padding: '10px 59px'}} className="card-header__text add-shadow">
            {/* <span style={{padding: '5px', border: '2px dashed #1fc8db',margin: '3px'}}></span>*/}
            Linterviews</span> 
          { previousScreen === "SIGN_UP" && <Home />}
          <form onSubmit={this.onSubmit}>
              <Row style={{display: 'flex',justifyContent: 'center',marginTop: '10px'}}>
                <Col md={12} style={{width: '250px'}}>
                  <Input
                    
                    style={{fontSize: '1.1em',height:'50px',padding: '10px',fontWeight: 'bold',textAlign:'center'}}
                    large
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                    onPressEnter={this.onSubmit}
                  />
                </Col>
              </Row>
              <Row style={{display: 'flex',justifyContent: 'center',marginTop: '10px'}}>
                <Col md={12} style={{width: '250px'}}>
                  <Input
                    style={{fontSize: '1.1em',height:'50px',padding: '10px',fontWeight: 'bold',textAlign:'center'}}
                    large
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                    onPressEnter={this.onSubmit}
                  />
                  </Col>
              </Row>
              <Row style={{display: 'flex',justifyContent: 'center',marginTop: '10px'}}>
                <Button disabled={isInvalid} type="submit" onClick={this.onSubmit}>
                  Sign In
                </Button>
              </Row>
              <div style={{display: 'flex',justifyContent: 'center',marginTop: '10px', color: '#ff7875'}}>
                   {error && <p>{error.message}</p>}
              </div>
          </form>
        </Row>
        { previousScreen !== 'SIGN_UP' && <><PasswordForgetLink /><SignUpLink /></>}
        <Row style={{marginTop: '25vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
          {`Code Crafted with ♡ for React by`}&nbsp;<a href="https://www.belvikram.com" target="_blank">Vikram Belde</a>
        </Row>
        {/* <PasswordForgetLink /> */}
      </Card>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
