import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//import './App.css'
import 'antd/dist/antd.css';
import { Button, TextField, FormLabel, TextareaAutosize } from '@material-ui/core';
//import Dropdown from './components/Dropdown'
//import { months, years, countries } from './constants'
import { Form, Icon, Input, Checkbox, Col, Row, Card, Tooltip } from 'antd';
// import { Slider } from "formik-antd";
import ResumeSchema from './resumeSchema'
import ResumeDefaultState from './resume.json'
import Location from './components/Location'
import Visas from './components/Visas';
import Projects from './components/Projects';
import SignInPage from './components/SignIn';
const initialState = {
  firstName: "",
  password: ""
}


// const projectSchema = Yup.object().shape({
//   firstName: Yup.string()
//     .min(5, "should be minimum 5 characters")
//     .required('Required'),
//   password: Yup.string()
//     .required('Required'),
// })

// const FormikField = ({ label, id, placeHolder, type, fieldValidation, ...rest }) =>
// {
//   const { errors, touched, values, handleChange, handleBlur } = rest
//   return (
//     <>
//       <Field
//         component={TextField}
//         id={id}
//         name={id}
//         placeholder={placeHolder || label}
//         type={type || "text"}
//         validate={fieldValidation}
//         onChange={handleChange}
//         onBlur={handleBlur}
//         value={values[id]}
//         label={label}
//         className={
//           errors[id] && touched[id] ? 'text-input error' : 'text-input'
//         }
//       />
//       <ErrorMessage name={id}></ErrorMessage>
//     </>
//   )
// }

class Login extends React.Component
{
  state = {
    projectCount: 0,

  }
  render()
  {
    // return <SignInPage />
    return (
      <div>
        <Formik
          initialValues={ResumeDefaultState}
          validateOnBlur
          validationSchema={ResumeSchema}
        >
          {props =>
          {
            const {
              values,
              dirty,
              isSubmitting,
              handleSubmit,
              handleReset,
              handleChange,
              handleBlur,
              errors,
              validateOnBlur,
              touched
            } = props;
            return (
              <>
                <Form onSubmit={handleSubmit}>
                  <Card style={{ background: '#ECECEC', padding: '30px' }}>
                    <Card >
                      <Row gutter={16}>
                        <Col md={3}>
                          <Form.Item label={(
                            <span>
                              First Name&nbsp;
                              <Tooltip title="Seriously ?? 😃">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )} >
                            <Field
                              component={Input}
                              id={"firstName"}
                              name={"firstName"}
                              placeholder={"First Name"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["firstName"]}
                              label={"label"}
                            // className={
                            //   errors["firstName"] && touched["firstName"] ? 'text-input error' : 'text-input'
                            // }
                            />
                            <ErrorMessage name={"firstName"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label={(
                            <span>
                              Last Name&nbsp;
                              <Tooltip title="Got you !!😃">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )}>
                            <Field
                              placeholder={"Last Name"}
                              component={Input}
                              id={"lastName"}
                              name={"lastName"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["lastName"]}
                            />
                            <ErrorMessage name={"lastName"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label="Phone*" >
                            <Field
                              component={Input}
                              placeholder={'123-1234-123'}
                              id={"phone"}
                              name={"phone"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["phone"]}
                            />
                            <ErrorMessage name={"phone"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label="Email*" >
                            <Field
                              component={Input}
                              placeholder={'myEmail@domain.com'}
                              id={"email"}
                              name={"email"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["email"]}
                            />
                            <ErrorMessage name={"email"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label={(
                            <span>
                              Total Experience*&nbsp;
                              <Tooltip title="Total years of experience as a developer including months">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )}>
                            <Field
                              component={Input}
                              id={"yearsOfExperienceInUS"}
                              name={"yearsOfExperienceInUS"}
                              type={"text"}
                              onChange={handleChange}
                              placeholder={'5 Years 2 Months'}
                              onBlur={handleBlur}
                              value={values["yearsOfExperienceInUS"]}
                            />
                            <ErrorMessage name={"yearsOfExperienceInUS"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label={(
                            <span>
                              Ready to join in&nbsp;
                              <Tooltip title="If you have a job offer, how long will you take to pack your bags ? 2 check-in bags is it 2 weeks?">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )}>
                            <Field
                              component={Input}
                              id={"readyToJoinIn"}
                              name={"readyToJoinIn"}
                              type={"text"}
                              onChange={handleChange}
                              placeholder={'2 weeks'}
                              onBlur={handleBlur}
                              value={values["readyToJoinIn"]}
                            />
                            <ErrorMessage name={"readyToJoinIn"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                      </Row>
                    </Card>
                    <Card>
                      <Row gutter={16}>
                        <Col md={7}>
                          <Form.Item label={(
                            <span>
                              Current Location*&nbsp;
                              <Tooltip title="Complete address including the zip code">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )}>
                            <Field
                              component={Input.TextArea}
                              id={"currentLocation"}
                              name={"currentLocation"}
                              onChange={handleChange}
                              placeholder={'123 Oxword Drive MarryLand 66648'}
                              onBlur={handleBlur}
                              value={values["currentLocation"]}
                            />
                            <ErrorMessage name={"currentLocation"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={7}>
                          <Form.Item label={(
                            <span>
                              About yourself&nbsp;
                              <Tooltip title={(<a target="_blank" href="https://www.google.com/search?q=take+a+deep+breath&rlz=1C5CHFA_enUS774US774&oq=Take+a+deep+breath&aqs=chrome.0.0l6.198j0j7&sourceid=chrome&ie=UTF-8">Take a Deep Breath </a>)}>
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )}>
                            <Field
                              component={Input.TextArea}
                              id={"aboutYourself"}
                              name={"aboutYourself"}
                              onChange={handleChange}
                              placeholder={'Hello, I am a homo sapien, an early hominid predecessors between 200000 and 300000 years ago. My primary skill is hunting but I am good at gathering and protecting caves.'}
                              onBlur={handleBlur}
                              value={values["aboutYourself"]}
                            />
                            <ErrorMessage name={"aboutYourself"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                      </Row>
                    </Card>
                    {/* <Location id="currentLocation" {...props} /> */}
                    <Projects id="projectsInUS" {...props}/>
                    {/* <Visas id="projectsInUS" {...props} /> */}
                    {/* <Visa id="visa" {...props} />
                    <Connect id="connect" {...props} /> */}
                  </Card>
                </Form>
              </>
            );
          }
          }
        </Formik >
      </div >
    )
  }
}


export default Login
{/* <Card>
                      <Row gutter={16} >
                        <Col md={3}>
                          <Form.Item label="gotoMyPCLoginEmail" >
                            <Field
                              component={Input}
                              id={"gotoMyPCLoginEmail"}
                              name={"gotoMyPCLoginEmail"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["gotoMyPCLoginEmail"]}
                            />
                            <ErrorMessage name={"gotoMyPCLoginEmail"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label="gotoMyPCPassword" >
                            <Field
                              component={Input}
                              id={"gotoMyPCPassword"}
                              name={"gotoMyPCPassword"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["gotoMyPCPassword"]}
                            />
                            <ErrorMessage name={"gotoMyPCPassword"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                        <Col md={3}>
                          <Form.Item label="gotoMyPCAccessCode" >
                            <Field
                              component={Input}
                              id={"gotoMyPCAccessCode"}
                              name={"gotoMyPCAccessCode"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["gotoMyPCAccessCode"]}
                            />
                            <ErrorMessage name={"gotoMyPCAccessCode"}></ErrorMessage>
                          </Form.Item >
                        </Col>
                      </Row>
                    </Card> */}
{/* <Card>
                      <Row gutter={16}>
                        {/* <Col md={3}>
                          <Form.Item label="Total Experience" >
                            <Field
                              component={Input}
                              id={"yearsOfExperienceInUS"}
                              name={"yearsOfExperienceInUS"}
                              type={"text"}
                              onChange={handleChange}
                              placeholder={'5 Years'}
                              onBlur={handleBlur}
                              value={values["yearsOfExperienceInUS"]}
                            />
                            <ErrorMessage name={"yearsOfExperienceInUS"}></ErrorMessage>
                          </Form.Item >
                        </Col> */}
{/* <Col md={3}>
                          <Form.Item label="No. Projects In US" >
                            <Field
                              component={Input}
                              id={"NumberOfProjectsInUS"}
                              name={"NumberOfProjectsInUS"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["NumberOfProjectsInUS"]}
                            />
                            <ErrorMessage name={"NumberOfProjectsInUS"}></ErrorMessage>
                          </Form.Item >
                        </Col> */}
{/* <Col md={3}>
                          <Form.Item label="No. Outside US" >
                            <Field
                              component={Input}
                              min={1}
                              max={20}
                              id={"NumberOfProjectsOutsideUS"}
                              name={"NumberOfProjectsOutsideUS"}
                              type={"text"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values["NumberOfProjectsOutsideUS"]}
                            />
                            <ErrorMessage name={"NumberOfProjectsOutsideUS"}></ErrorMessage>
                          </Form.Item >
                        </Col> 
                      </Row>
                    </Card> */}



{/* <FormikField placeHolder="User Name" id={"firstName"} label="User Name" {...props} />
                <FormikField placeHolder="Password" id={"password"} label="password" {...props} /> */}

{/* <Button
                  type="button"
                  className="outline"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Register
                </Button> */}