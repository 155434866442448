import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  duringQuestionsToAsk,
  duringNotes,
  duringThingsToFocus
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button } from 'antd';

// const InterviewHeaderSchema = Yup.object().shape({
//   [jobTitle]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
//   [jobLocation]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
//   [jobDescription]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
// });

class PreInterviewEdit extends React.Component {
  render() {
    return (
      <>
        <Formik
          initialValues={this.props.values}
          validateOnBlur
          //validationSchema={InterviewHeaderSchema}
        >
          {props => {
            const {
              values,
              handleChange,
              handleBlur,
              errors,
              setErrors
            } = props;


            //const isValid = InterviewHeaderSchema.isValidSync(values);

            const getInputProps = id => ({
              id,
              value: values[id],
              error: errors[id],
              onChange: handleChange,
              onBlur: handleBlur,
            })

            const handleSave = () =>this.props.handleSave(values)

            return (
              <>
                <Card
                  extra={
                    this.props.values.isNew ?
                      <Row gutter={16}>
                        <Col md={12}>
                          <Button onClick={handleSave} > {/** disabled={!isValid} */}
                            Add
                          </Button>
                        </Col>
                      </Row> :
                      <Row gutter={16}>
                        <Col md={12}>
                          <Button onClick={handleSave} type="primary">
                            Save
                          </Button>
                        </Col>
                        <Col md={12}>
                          <Button onClick={this.props.handleCancel}>
                            Cancel
                          </Button>
                        </Col>
                      </Row>

                  }
                  title={"During Interview"}
                >
                  <Row>
                    <InputMaker
                      rows={5}
                      type="textArea" 
                      {...getInputProps(duringThingsToFocus)} 
                    />
                  </Row>
                  <Row>
                    <InputMaker 
                      rows={5}
                      type="textArea" 
                      {...getInputProps(duringQuestionsToAsk)} 
                    />
                  </Row>
                  <Row>
                    <InputMaker 
                      rows={5}
                      type="textArea" 
                      {...getInputProps(duringNotes)} 
                    />
                  </Row>
                </Card>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
export default withFirebase(PreInterviewEdit);
