import React, { Component } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import moment from 'moment';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { DropDownMenu } from 'material-ui';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Select,
  Radio,
  TimePicker,
} from 'antd';
import { AuthUserContext } from '../../components/Session';
import { withFirebase } from '../../components/Firebase';
const { Option } = Select;
const { TextArea } = Input;

class Comments extends Component {
  state = {
    comments: {},
    newComment: '',
    count: 0,
  };

  handleAddComment = () => {
    const { comments, newComment } = this.state;
    const commentsDeepCopy = JSON.parse(JSON.stringify(comments));
    const uniqueId = new Date().getTime();
    commentsDeepCopy[uniqueId] = {
      comment: newComment,
      timeStamp: uniqueId,
    };
    console.log(commentsDeepCopy);

    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/comments`)
        .set(commentsDeepCopy, () => {
          this.setState({
            comments: commentsDeepCopy,
            newComment: '',
          });
        });
    });
  };

  handleChange = ({ target: { value } }) => {
    this.setState({
      newComment: value,
    });
  };

  handleDelete = timeStamp => {
    const { comments, count } = this.state;

    if (count > 5) {
      const commentsDeepCopy = JSON.parse(JSON.stringify(comments));
      delete commentsDeepCopy[timeStamp];
      this.props.firebase.onAuthUserListener(authUser => {
        database()
          .ref('users/' + authUser.uid + `/comments`)
          .set(commentsDeepCopy, () => {
            this.setState({
              comments: commentsDeepCopy,
              newComment: '',
              count: 0,
            });
          });
      });
    }

    this.setState({
      count: count + 1,
    });
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/comments')
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            console.log('snapshot.val(): ', snapshot.val());
            this.setState({
              comments: snapshot.val(),
            });
          }
        });
    });
  }

  render() {
    const { comments, newComment } = this.state;
    const chronologicalComments = getChronologicalComments(comments);
    console.log(
      '🚀 ~ file: index.js ~ line 105 ~ Comments ~ render ~ chronologicalComments',
      chronologicalComments,
    );

    return (
      <Card style={{ background: '#ECECEC' }}>
        <div>
          <div>
            <TextArea
              style={{ width: '100%', height: '100px' }}
              value={newComment}
              onChange={this.handleChange}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button
              disabled={!newComment}
              onClick={this.handleAddComment}
            >
              Add
            </Button>
          </div>
          {chronologicalComments.map(each => (
            <Comment
              {...each}
              key={each.timeStamp}
              handleOnDelete={this.handleDelete}
            />
          ))}
        </div>
      </Card>
    );
  }
}

export const Comment = ({
  comment = '',
  timeStamp,
  handleOnDelete,
}) => {
  return (
    <Card
      style={{ margin: '20px', fontSize: '0.6rem' }}
      onClick={() => {
        handleOnDelete(timeStamp);
      }}
    >
      {getReadableTimeStamp(timeStamp)}
      <div style={{ margin: '20px', fontSize: '1rem' }}>
        {' '}
        {comment}{' '}
      </div>
    </Card>
  );
};

const getReadableTimeStamp = unix =>
  moment(unix).format('MMMM Do YYYY, h:mm:ss a');

const getChronologicalComments = comments => {
  return Object.keys(comments)
    .map(commentId => comments[commentId])
    .sort((a, b) => b.timeStamp - a.timeStamp);
};

export default withFirebase(Comments);
