import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import Firebase, { withFirebase } from './components/Firebase';
import { AuthUserContext } from './components/Session';
import { Form, Icon, Input, Row, Col, Card, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';

class EmployerDetails extends React.Component {
  state = {
    employerCompanyName: '',
    employerContactPersonName: '',
    employerPhone: '',
    employerEmail: '',
    employerAddress: '',
    isLoading: true
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/employer/')
        .once('value')
        .then(snapshot => {
          this.setState({...snapshot.val() || {}, isLoading: false});
        });
    });
  }

  saveToFirebase = authUser => {
    const values = this.state;
    const userId = authUser.uid;
    database()
      .ref('users/' + userId + '/employer/')
      .set(values);
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Card loading={this.state.isLoading}>
            <Formik
              initialValues={this.state}
              validateOnBlur
              //validationSchema={projectSchema}
              enableReinitialize
            >
              {props => {
                const {
                  values,
                  // dirty,
                  // isSubmitting,
                  handleSubmit,
                  handleReset,
                  handleChange,
                  handleBlur,
                  errors,
                  // validateOnBlur,
                  // touched,
                } = props;

                const UpdateForm = e => {
                  handleChange(e);
                  const {
                    target: { id, value },
                  } = e;
                  this.setState(
                    {
                      [id]: value,
                    },
                    () => {
                      this.saveToFirebase(authUser);
                    },
                  );
                };

                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Card
                        style={{
                          background: '#ECECEC',
                          padding: '10px',
                        }}
                      >
                        <Card>
                          <Row>
                            {/* <Col md={6}>
                              <Button>
                                <Link to={'/education'}>
                                  Previous
                                </Link>
                              </Button>
                            </Col>
                            <Col className="next-button">
                              <Button type="primary">
                                <Link to={'/connect'}>
                                  Next
                                </Link>
                              </Button>
                            </Col> */}
                          </Row>
                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Employer Company Name&nbsp;
                                  <Tooltip title="Who owns your Visa">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'employerCompanyName'}
                                name={'employerCompanyName'}
                                placeholder={'Edisav le Consulting Group, inc.'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['employerCompanyName']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['employerCompanyName']}
                              </span>
                            </Form.Item>
                          </Row>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Contact Person&nbsp;
                                  <Tooltip title="This can be your marketing Person">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'employerContactPersonName'}
                                name={'employerContactPersonName'}
                                placeholder={'Pentaiah'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={
                                  values['employerContactPersonName']
                                }
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['employerContactPersonName']}
                              </span>
                            </Form.Item>
                          </Row>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Phone&nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'employerPhone'}
                                name={'employerPhone'}
                                placeholder={'123-456-7890'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['employerPhone']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['employerPhone']}
                              </span>
                            </Form.Item>
                          </Row>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Email&nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'employerEmail'}
                                name={'employerEmail'}
                                placeholder={'pentaiah@Edisavle.com'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['employerEmail']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['employerEmail']}
                              </span>
                            </Form.Item>
                          </Row>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Office Address&nbsp;
                                  <Tooltip title="Seriously ?? 😃">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input.TextArea}
                                rows={4}
                                id={'employerAddress'}
                                name={'employerAddress'}
                                placeholder={'1234 N Thupas Rd, Ohio, 12345'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['employerAddress']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['employerAddress']}
                              </span>
                            </Form.Item>
                          </Row>
                        </Card>
                      </Card>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Card>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
export default withFirebase(EmployerDetails);
