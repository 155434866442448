import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  postInterviewRating,
  postQuestionsAsked,
  postNotes,
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu } from 'antd';
import getUrlParam from '../../utilities/getUrlParams';
import { Link } from 'react-router-dom';

// const InterviewHeaderSchema = Yup.object().shape({
//   [jobTitle]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
//   [jobLocation]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
//   [jobDescription]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
// });



class PostInterviewView extends React.Component {

  menu = () => {

    const interviewId = getUrlParam('iid')
    return(
      <Menu>
        { 
          Object.keys(this.props.values.interview.rounds).map(key=>{
            const round = this.props.values.interview.rounds[key]
            return(
              <Menu.Item key={round.roundId}>
                <Link to={`/round?iid=${interviewId}&rid=${round.roundId}`}>
                  {round.roundNumber}
                </Link>
              </Menu.Item>
            )
          })
        }
      </Menu>
    )
  };

  roundsHeader = () => {
    const interviewId = getUrlParam('iid')
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/interview?iid=${interviewId}`}>
            {this.props.values.interview.interviewClient}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item overlay={this.menu()}>
          <a>Rounds</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  getFieldDisplayProps = id => {
    return {
      value: this.props.values[id],
      id,
    };
  };

  render() {
    // const { isEditing, isLoading, currentState } = this.props;

    return (
      <Card
        className="add-shadow"
        // title={this.roundsHeader()}
        title={<span className="card-header__label">{'Post Interview'}</span>}
        extra={
          <>
            <Button onClick={this.props.handleEdit}>Edit</Button>
          </>
        }
      >
        <Row>
          <FieldDisplay
            textArea
            rows={5}
            {...this.getFieldDisplayProps(postInterviewRating)}
          />
        </Row>
        <Row>
          <FieldDisplay
            textArea
            rows={5}
            {...this.getFieldDisplayProps(postQuestionsAsked)}
          />
        </Row>
        <Row>
          <FieldDisplay
            textArea
            rows={5}
            {...this.getFieldDisplayProps(postNotes)}
          />
        </Row>
      </Card>
    );
  }
}
export default withFirebase(PostInterviewView);
