import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  interviewDate,
  interviewFromTime,
  interviewToTime,
  timeZone,
  interviewMode,
  thingsToFocus,
  interviewNotes,
  linkToInterviewInvite,
  roundNumber
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button } from 'antd';

// const InterviewHeaderSchema = Yup.object().shape({
//   [jobTitle]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
//   [jobLocation]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
//   [jobDescription]: Yup.string()
//     .min(3, 'min 3 characters')
//     .required('Required'),
// });

class PreInterviewEdit extends React.Component {
  render() {
    return (
      <>
        <Formik
          initialValues={this.props.values}
          validateOnBlur
          //validationSchema={InterviewHeaderSchema}
        >
          {props => {
            const {
              values,
              handleChange,
              handleBlur,
              errors,
              setErrors
            } = props;


            //const isValid = InterviewHeaderSchema.isValidSync(values);

            const getInputProps = id => ({
              id,
              value: values[id],
              error: errors[id],
              onChange: handleChange,
              onBlur: handleBlur,
            })

            const handleSave = () =>this.props.handleSave(values)

            return (
              <>
                <Card
                  extra={
                    this.props.values.isNew ?
                      <Row gutter={16}>
                        <Col md={12}>
                          <Button onClick={handleSave} > {/** disabled={!isValid} */}
                            Add
                          </Button>
                        </Col>
                      </Row> :
                      <Row gutter={16}>
                        <Col md={12}>
                          <Button onClick={handleSave} type="primary">
                            Save
                          </Button>
                        </Col>
                        <Col md={12}>
                          <Button onClick={this.props.handleCancel}>
                            Cancel
                          </Button>
                        </Col>
                      </Row>

                  }
                  title={"Editing Pre Interview"}
                >
                  <InputMaker {...getInputProps(roundNumber)} />
                  <Row gutter={16}>
                    <Col md={6}>
                      <InputMaker type="date" {...getInputProps(interviewDate)} />
                    </Col>
                    <Col md={6}>
                      <InputMaker type="time" {...getInputProps(interviewFromTime)} />
                    </Col>
                    <Col md={6}>
                      <InputMaker type="time" {...getInputProps(interviewToTime)} />
                    </Col>
                    <Col md={6}>
                      <InputMaker type="dropdown" 
                        options={['CST','PST','MST','EST']}
                        {...getInputProps(timeZone)}
                      />
                    </Col>
                  </Row>
                  <InputMaker 
                    type="dropdown" 
                    options={['WebEx','Zoom','Skype','Microsoft Teams','GotoMeeting','Phone']}
                  {...getInputProps(interviewMode)} />
                  <InputMaker {...getInputProps(linkToInterviewInvite)} />
                  <Row>
                    <InputMaker type="textArea" rows={5} {...getInputProps(thingsToFocus)} />
                    <InputMaker type="textArea" rows={5} {...getInputProps(interviewNotes)} />
                  </Row>
                </Card>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
export default withFirebase(PreInterviewEdit);
