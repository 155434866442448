import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import {
  Card,
  Icon,
  Button,
  Input,
  Row,
  Col
} from 'antd'
import { Link } from 'react-router-dom';
class EmailNotVerified extends React.Component{

  state = {
    email: '',
    isSending: false,
    isVerificationEmailSent: false
  }

  handleChange = (e) =>{
    this.setState({
      email : e.target.value
    })
  }

  handleSendVerificationEmail = () =>{
    this.setState({
      isSending: true
    })

    // setTimeout(() => {
    //   this.setState({ isVerificationEmailSent: true, isSending: false })
    // }, 1000);
    this.props.firebase
      .doSendEmailVerification()
      .then(() => this.setState({ isVerificationEmailSent: true, isSending: false }));
  }

  render(){
    const { email, isVerificationEmailSent, isSending} = this.state
    return(
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>  
          <Icon style={{ fontSize: '50px', color: '#ff7875' }} type="mail" />
          <h2>E-Mail Not Verified</h2>
          {/* <h4>We at linterviews Love to have You, just one more step, before we can dine together....</h4> */}
          <h6 style={{textAlign: 'center'}}>Check your E-Mails (Spam folder included) for a confirmation E-Mail.</h6>
          <h3 style={{textAlign: 'center'}}><Link to='/'>Sign in </Link> back once you confirmed your E-Mail</h3>
          {/* <Row style={{display: 'flex',justifyContent: 'center',margin: '10px'}} gutter={16}>
            <Col md={12} style={{width: '200px'}}>
              <Input
                style={{fontSize: '1.1em',height:'35px',padddin: '10px',fontWeight: 'bold',textAlign:'center'}}
                large
                name="email"
                value={email}
                onChange={this.handleChange}
                type="text"
                placeholder="Email Address"
                onPressEnter={this.onSubmit}
              />
            </Col>
          </Row> */}
          <Row>
              {isVerificationEmailSent ? <h3>Email Sent ! </h3> : <Button size='large' onClick={this.handleSendVerificationEmail}> {isSending ? <Icon type="loading" style={{ fontSize: 12 }}/> : 'Send Verification email'}</Button> }
          </Row>
        </div>
    </Card>
    )
  }
};

export default withFirebase(EmailNotVerified)