import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import {
  Form,
  Icon,
  Input,
  Col,
  Row,
  Card,
  Tooltip,
  Button,
} from 'antd';
import Firebase, { withFirebase } from './components/Firebase';
import { Link } from 'react-router-dom';
import StatusIcon from './components/StatusIcon';
import Status from './components/Status';

const userPersonalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  lastName: Yup.string()
    .min(3, 'min 3 characters')
    .required('Required'),
  phone: Yup.string()
    .matches(/^[1-9]\d{9}$/, {
      message: 'invalid',
      excludeEmptyString: false,
    })
    .required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
});

class LiveStudent extends React.Component {
  state = {
    trainerNotes: {},
    isTrainerOnline: false,
    isFirstFetch: false,
    studentNotes: '',
  };

  // componentWillMount() {
  //   //var ref = firebase.database().ref('live-trainer');
  //   database()
  //     .ref('live-trainer')
  //     .on('value', snapshot => {
  //       if (snapshot.val()) {
  //         this.setState({
  //           trainerNotes: snapshot.val(),
  //         });

  //         if(!this.state.isFirstFetch){
  //           this.setState({
  //             isFirstFetch: true,
  //           })
  //         }else{
  //           this.setState({
  //             isTrainerOnline: true
  //           })
  //         }
  //       }
  //     });
  // }


  componentDidMount() {
    //var ref = firebase.database().ref('live-trainer');
    database()
      .ref('live-trainer')
      .on('value', snapshot => {
        if (snapshot.val()) {
          this.setState({
            trainerNotes: snapshot.val(),
          });

          if(!this.state.isFirstFetch){
            this.setState({
              isFirstFetch: true,
            })
          }else{
            this.setState({
              isTrainerOnline: true
            })
          }
        }
      });
    // setInterval(() => {
    //   if(this.state.isTrainerOnline){
    //     this.setState({
    //       isTrainerOnline: false
    //     })
    //   }
    // }, 1000);
  }

  saveToFirebaseFromStudent = e => {
    const { value } = e.target;
    this.props.firebase.onAuthUserListener(authUser => {
      // const values = this.formValues;
      // const errors = this.formErrors;
      // const userId = authUser.uid;
      // const isValid = Object.entries(errors).length === 0
      database()
        .ref('live-student')
        .set(
          {
            value,
          },
          () => {
            this.setState({
              studentNotes: value,
            });
          },
        );
    });
  };

  render() {
    let keys = [];

    for (let key in this.state.trainerNotes) {
        keys.push(key);
    }

    keys.reverse()
    // console.log("Keys",keys)
    return (
      <Card style={{ background: '#ECECEC' }}>
                
        <Input.TextArea onChange={this.saveToFirebaseFromStudent} />
        {
        keys.map((key,index )=> {
          return (
            <Card size="small" title={this.getTitle(index)} style={{ padding: '30px', marginTop: '10px' }} key={this.state.trainerNotes[key]}>
              <p style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}> {this.state.trainerNotes[key].value}</p>
            </Card>
          );
        })}
      </Card>
    );
  }

  getTitle = index => {
    return !index ? <span> <div className='typing-text'/><Status />Current: </span> : 'Previous:'
  }
}

export default withFirebase(LiveStudent);
