import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { Card, Icon, Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { database } from 'firebase';
import { withFirebase } from '../Firebase';
import moment from 'moment';
import './interview-grid.scss';
const { Text } = Typography;
const proTips = [
  'You can add multiple rounds for a interview',
  '`Interview Mode` is a special screen designed to easily access key information during interview. access it via interviews > round > Interview Mode',
  'Be ahead by preparing the curated list of interview questions from various clients for Frontend Engineer at www.belvikram.com/#/interviewQuestions',
];

class InterviewGrid extends React.Component {
  state = {
    interviews: [],
    isLoading: true,
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/interviews/')
        .once('value')
        .then(snapshot => {
          const interviewsObj = snapshot.val() || {};
          let interviews = Object.keys(interviewsObj).map(key => ({
            id: key,
            ...interviewsObj[key],
          }));
          this.setState({
            isLoading: false,
            interviews,
          });
        });
    });
  }
  render() {
    const { interviews } = this.state;
    return (
      <Card
        loading={this.state.isLoading}
        style={{
          // margin: '20px',
          backgroundColor: '#f2f2f2',
          height: '100vh',
        }}
        className="add-shadow"
      >
        <Text
          mark
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Pro Tip:{' '}
          {proTips[Math.floor(Math.random() * proTips.length)]}
        </Text>
        <div className="interview-container">
          {interviews.map(interview => {
            const time = interview.interviewFromTime
              ? moment(interview.interviewFromTime).format('HH:mm a')
              : 'Not Set';
            const date = interview.interviewDate || 'Not Set';
            const rounds = interview.rounds || {};
            return (
              <Link
                to={`/interview/?iid=${interview.id}`}
                key={interview.id}
              >
                <Card
                  hoverable
                  //title={<span className="interview-client__name">{interview.interviewClient || 'Not Set'}</span>}
                  className="interview-card"
                >
                  <div className="interview-card__content">
                    <span className="interview-client__name">
                      {interview.interviewClient || 'Not Set'}
                    </span>
                    <span className="interview-client__rounds">
                      {`${Object.keys(rounds).length} Rounds` ||
                        'No Rounds'}
                    </span>
                  </div>
                  {/* <h3>{`Time ${time}`}</h3> */}
                  {/* <h3>{`Date ${date}`}</h3> */}
                  {/* {Object.keys(rounds).map(level => {
                    console.log('rounds: ', rounds);
                    return <Card>{`Level ${level.level}`}</Card>;
                  })} */}
                </Card>
              </Link>
            );
          })}
          <Link to="/interview?iid=new">
            <Card
              loading={this.state.isLoading}
              className="interview-card__add"
              hoverable
            >
              <Icon
                type="plus-circle"
                className="interview-card__icon"
              />
              <div className="interview-card__text">
                add upcoming interview
              </div>
            </Card>
          </Link>
        </div>
      </Card>
    );
  }
}

export default withFirebase(InterviewGrid);

// const interviews = [
//   {
//     company: 'CLIENT A',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT A',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT A',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT A',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company:
//       'CLIENT A CLIENT A CLIENT A CLIENT A CLIENT A CLIENT A CLIENT A',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT A CLIENT A',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT B',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT C',
//     levels: [
//       {
//         level: 1,
//       },
//     ],
//   },
//   {
//     company: 'CLIENT D',
//     levels: [
//       {
//         level: 1,
//       },
//       {
//         level: 2,
//       },
//       {
//         level: 3,
//       },
//     ],
//   },
// ];
