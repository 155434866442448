import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { database } from 'firebase';
import { withFirebase } from './components/Firebase';
import { AuthUserContext } from './components/Session';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  DatePicker,
  Button,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

class Connect extends React.Component {
  state = {
    userName: '@gmail.com',
    password: 'LetsMeet@1',
    accessCode: 'abcd12345',
    createdOn: '',
    status: '',
    isLoading: true,
  };

  componentDidMount() {
    this.props.firebase.onAuthUserListener((authUser) => {
      database()
        .ref('users/' + authUser.uid + '/connect/')
        .once('value')
        .then((snapshot) => {
          this.setState({
            ...(snapshot.val() || {}),
            isLoading: false,
          });
        });
    });
  }

  saveToFirebase = (authUser) => {
    const values = this.state;
    const userId = authUser.uid;
    database()
      .ref('users/' + userId + '/connect/')
      .set(values);
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Card loading={this.state.isLoading} title="">
            <Formik
              initialValues={this.state}
              validateOnBlur
              //validationSchema={projectSchema}
              enableReinitialize
            >
              {(props) => {
                const {
                  values,
                  // dirty,
                  // isSubmitting,
                  handleSubmit,
                  handleReset,
                  handleChange,
                  handleBlur,
                  errors,
                  // validateOnBlur,
                  // touched,
                } = props;
                const { createdOn } = values;
                const UpdateForm = (e) => {
                  handleChange(e);
                  const {
                    target: { id, value },
                  } = e;
                  this.setState(
                    {
                      [id]: value,
                    },
                    () => {
                      this.saveToFirebase(authUser);
                    },
                  );
                };

                const UpdateDate = (e) => {
                  handleChange(e);
                  const {
                    target: { id, value },
                  } = e;
                  this.setState(
                    {
                      [id]: value || '',
                    },
                    () => {
                      this.saveToFirebase(authUser);
                    },
                  );
                };

                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Card
                        style={{
                          background: '#ECECEC',
                          padding: '10px',
                        }}
                      >
                        <Card>
                          <p
                            style={{
                              whiteSpace: 'pre-line',
                              wordBreak: 'break-all',
                            }}
                          >
                            <h3>
                              At any point of the course if you have
                              issue, please create a JSFiddle and
                              share with the Trainer. The Trainer will
                              connect using below software to control
                              and resolve your issue.
                              <br />
                              To recreate your issues: use the
                              following JSFiddle link as boiler plate.
                              <a
                                href="https://jsfiddle.net/boilerplate/react-jsx"
                                target="_blank"
                              >
                                {' '}
                                React Boiler Plate{' '}
                              </a>
                            </h3>
                            <h1 style={{ color: 'red' }}>
                              THIS SET UP ONLY WORKS FOR WINDOWS, ASK
                              TRAINER FOR MAC SET UP
                            </h1>
                            <h1 style={{ color: 'red' }}>
                              PLEASE USE THE GIVEN PASSWORD AND ACCESS
                              CODE
                            </h1>
                            <h2>Details to join the session:</h2>
                            {/* <p className='step'>Step 1</p><p>Go to <a href="https://www.gotomypc.com" target="_blank">this website</a></p> */}
                            <p className="step">Step 1</p>
                            <p className="step-detail">
                              Register a account with trial version
                              here{' '}
                              <a
                                target="_blank"
                                href="https://www.gotomypc.com/members/register.tmpl?Location=en_US&FreeTrial=true"
                              >
                                click here
                              </a>
                            </p>
                            <div className="credentials">
                              UserName: put your email{'\n'}
                              Password: LetsMeet@1 {'\n'}
                            </div>
                            <p className="step">Step 2</p>{' '}
                            <p className="step-detail">
                              {' '}
                              After you register, you will receive an
                              email. Please open the email and verify
                              the account.{'\n'}
                            </p>
                            <p className="step">Step 3</p>{' '}
                            <p className="step-detail">
                              {' '}
                              Go back to your account and click on “+
                              Add this computer”, this will download a
                              plugin. Install the plugin.{'\n'}
                            </p>
                            <p className="step">Step 4</p>{' '}
                            <p className="step-detail">
                              {' '}
                              After installing the plugin, click on
                              the plugin(should be in the taskbar
                              aside to time on windows), please put
                              your user name and password you entered
                              while you registered.{'\n'}
                            </p>
                            <p className="step">Step 5</p>{' '}
                            <p className="step-detail">
                              {' '}
                              it Should ask for access code, put the
                              access code as{' '}
                            </p>
                            <div className="credentials">
                              Access code: abcd12345
                            </div>
                            <p className="step">Step 6</p>
                            <p className="step-detail">
                              {' '}
                              Go back to your account on gotoPC
                              website and you should see your computer
                              added successfully{'\n'}{' '}
                            </p>
                          </p>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  UserName &nbsp;
                                  <Tooltip title="Use your gmail id as user name">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'userName'}
                                name={'userName'}
                                placeholder={'jonathan@gmail.com'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['userName']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['userName']}
                              </span>
                            </Form.Item>
                          </Row>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  password&nbsp;
                                  <Tooltip title="Use password LetsMeet@1">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'password'}
                                name={'password'}
                                placeholder={'LetsMeet@1'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['password']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['password']}
                              </span>
                            </Form.Item>
                          </Row>

                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  Access Code&nbsp;
                                  <Tooltip title="use Access Code as abcd12345">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <Field
                                component={Input}
                                id={'accessCode'}
                                name={'accessCode'}
                                placeholder={'Super Corp'}
                                type={'text'}
                                onChange={UpdateForm}
                                onBlur={handleBlur}
                                value={values['accessCode']}
                                label={'label'}
                              />
                              <span className="error-message">
                                {errors['accessCode']}
                              </span>
                            </Form.Item>
                          </Row>
                          <Row md={3}>
                            <Form.Item
                              label={
                                <span>
                                  created on&nbsp;
                                  <Tooltip title="Date of creation of Go to my pc account">
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                              }
                            >
                              <DatePicker
                                id="createdOn"
                                value={moment(createdOn) || null}
                                onChange={(e) =>
                                  e
                                    ? UpdateForm({
                                        target: {
                                          value: e.format(
                                            'MMM DD YYYY',
                                          ),
                                          id: 'createdOn',
                                        },
                                      })
                                    : UpdateForm({
                                        target: {
                                          value: null,
                                          id: 'createdOn',
                                        },
                                      })
                                }
                              />
                              <span className="error-message">
                                {errors['createdOn']}
                              </span>
                            </Form.Item>
                          </Row>
                          <Row>
                            Status:{' '}
                            {moment(new Date()).diff(
                              createdOn,
                              'days',
                            ) >= 7
                              ? 'Not Good'
                              : 'Good'}
                            (Gotomypc account expires after 7 days of
                            creation)
                          </Row>
                          <Col className="next-button">
                            <Row>
                              <Col md={6}>
                                <Button type="primary">
                                  <Link to={'/profile'}>
                                    Verify Profile
                                  </Link>
                                </Button>
                              </Col>
                            </Row>
                            {/* <Button>
                              <a
                                href="https://www.belvikram.com/#/interviewQuestions"
                                target="_blank"
                              >
                                {' '}
                                Done (Prepare For Interview){' '}
                              </a>
                            </Button> */}
                          </Col>
                        </Card>
                      </Card>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Card>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
export default withFirebase(Connect);
