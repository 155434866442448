import React from 'react';
import { Icon, DatePicker, Tooltip, Select } from 'antd';
import moment from 'moment';
import verbiage from '../constants/verbage.json';

const { Option } = Select;

function Dropdown({ options=[], value, onChange, id }) {
  
  return (
    <Select
      id={id}
      defaultValue="select"
      value={value}
      onChange={(e)=>{
        onChange({target: {value: e, id}})
      }}
    >
      <Option value="select">Select</Option>
      {
        options.map(option=>{
          return <Option value={option} key={option}>{option}</Option>
        })
      }
    </Select>
  );
}

export default Dropdown;
