import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { Card, Button, Input, Row, Col, Icon } from 'antd';
import { get } from 'lodash'

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

function getInitialStateBasedOnEnv(env){
  if(process.env.NODE_ENV === 'development'){
    return {
      username: 'TEST',
      email: `belvikram+${Math.random()}@gmail.com`,
      passwordOne: 'Verizon7',
      passwordTwo: 'Verizon7',
      isAdmin: false,
      error: null,
      isSigningUp: false
    }
  }

  return{
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null,
    isSigningUp: false
  }
}

const INITIAL_STATE = getInitialStateBasedOnEnv(process.env.NODE_ENV);

// const INITIAL_STATE = {
//   username: 'TEST',
//   email: `belvikram+${Math.random()}@gmail.com`,
//   passwordOne: 'Verizon7',
//   passwordTwo: 'Verizon7',
//   isAdmin: false,
//   error: null,
//   isSigningUp: false
// };

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    this.setState({
      isSigningUp: true
    })

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return authUser;
        // Create a user in your Firebase realtime database
        // return this.props.firebase.user(authUser.user.uid).set({
        //   username,
        //   email,
        //   roles,
        // });
      })
      .then(user => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({
          isSigningUp: false
        })
        // this.setState({ ...INITIAL_STATE });
        this.props.history.push({
          pathname: '/',
          props : {
            email: this.state.email,
            previousScreen: 'SIGN_UP'
          }
        });
      })
      .catch(error => {
        this.setState({
          isSigningUp: false
        })
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <Card
        style={{
          padding: '20px',
          display: 'flex',
          width: '100vw',
          justifyContent: 'center',
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '50px', fontWeight: '800', color:'#000' }}>
            Linterviews
          </span>
          <p>Lint your Interviews ahead</p>
        </Row>
        <form onSubmit={this.onSubmit}>
          <Row gutter={16}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Input
                name="username"
                value={username}
                onChange={this.onChange}
                type="text"
                placeholder="Full Name"
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Input
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Input
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Input
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirm Password"
              />
            </Col>
          </Row>
          {/* <label>
          Admin:
          <Input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />  
        </label> */}
          <Row style={{display: 'flex',justifyContent: 'center',marginTop: '10px'}}>
            <Button disabled={isInvalid} type="submit" onClick={this.onSubmit}>
              {this.state.isSigningUp ? <Icon type="loading" style={{ fontSize: 12 }}/> : 'Sign up'}
            </Button>
          </Row>
          {error && <p>{error.message}</p>}
        </form>
      </Card>
    );
  }
}

const SignUpLink = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <div
      style={{ marginRight: '10px' }}
    >{` Don't have an account? `}</div>
    <Link to={ROUTES.SIGN_UP}> {`Sign Up`}</Link>
  </div>
);

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
