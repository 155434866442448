import React from 'react';
import { Icon, DatePicker, Tooltip } from 'antd';
import moment from 'moment';

import verbiage from '../constants/verbage.json'

function Datepicker({ value, onChange, id }){
  return (
    <>
      <DatePicker
        format={"DD MMM YYYY"}
        id={id}
        value= {value ? moment(value): null}
        onChange={e =>
          e
            ? onChange({
                target: {
                  value: e.format('MMM DD YYYY'),
                  id
                },
              })
            : onChange({
                target: {
                  value: null,
                  id
                },
              })
        }
      />
    </>
  );
};

export default Datepicker
