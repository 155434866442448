import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Login from '../../Login';
import Comments from '../Comments';

import * as ROUTES from '../../constants/routes';
import {
  withAuthentication,
  withEmailVerification,
} from '../Session';
import EmailNotVerified from '../EmailNotVerified';
import Interviews from '../InterviewsGrid';
import Interview from '../Interview';
import Round from '../Round';
import Experience from '../../Experience';
import Visa from '../Visa/VisaIndex';
import EmployerDetails from '../../EmployerDetails';
// import Resume from '../../resume';
import ProjectsTabs from '../../ProjectsTabs';
import Connect from '../../Connect';
import PersonalDetails from '../PersonalDetails/PersonalDetailsIndex';
import Education from '../Education/EducationIndex';
//import Resume from '../Resume';
import Resume from '../Resume/ResumeIndex';
import ResumeUpload from '../ResumeUpload/ResumeUploadIndex';
import Booking from '../../Booking';
import LiveTrainer from '../../LiveTrainer';
import LiveStudent from '../../LiveStudent';
import Profile from '../../Profile';
import ResumeTemplate from '../ResumeTemplate';
import PublicResume from '../PublicResume';
import Wizard from '../Wizard/Wizard';
//import Education from '../../Education';

const App = () => (
  <Router>
    <div>
      <Navigation />
      {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
      <Route exact path={'/'} component={SignInPage} />
      <Route exact path={'/interview'} component={Interview} />
      <Route exact path={'/round'} component={Round} />
      <Route exact path={'/interviews'} component={Interviews} />
      <Route exact path={'/projects'} component={ProjectsTabs} />
      <Route exact path={'/experience'} component={Experience} />
      <Route exact path={'/visa'} component={Visa} />
      <Route exact path={'/employer'} component={EmployerDetails} />
      <Route
        exact
        path={'/personalDetails'}
        component={PersonalDetails}
      />
      <Route exact path={'/verify'} component={EmailNotVerified} />
      <Route exact path={'/resume'} component={Resume} />
      <Route exact path={'/upload'} component={ResumeUpload} />
      <Route exact path={'/connect'} component={Connect} />
      <Route exact path={'/education'} component={Education} />
      <Route exact path={'/booking'} component={Booking} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={'/live-trainer'} component={LiveTrainer} />
      <Route path={'/live-student'} component={LiveStudent} />
      <Route path={'/profile'} component={Profile} />
      <Route path={'/login'} component={Login} />
      <Route path={'/publish'} component={ResumeTemplate} />
      <Route path={'/r/:id'} component={PublicResume} />
      <Route path={'/form'} component={Wizard} />
      <Route path={'/comments'} component={Comments} />
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      {/* <Route path={'/'} component={publicResume} /> */}
    </div>
  </Router>
);

export default withAuthentication(App);
