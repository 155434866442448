import React from 'react';
import _ from 'lodash';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import InterviewMainDetailsEdit from '../Interview/interviewMainDetailsEdit';
import InterviewMainDetailsView from '../Interview/interviewMainDetailsView';

import { Card, Col, Row, Button } from 'antd';
import getUrlParam from '../../utilities/getUrlParams';

class InterviewMainDetails extends React.Component {
  state = {
    jobTitle: '',
    jobLocation: '',
    jobDescription: '',
    referredBy: '',
    salary: '',
    interviewClient: '',
    aboutCompany: '',
    interviewMainNotes: '',
    implementationPartner: '',
    vendorDetails: '',
    readyToJoinIn: '',
    isEditing: false,
    isLoading: true,
    isSaving: false,
    isNew: false,
    interviewId: 'new',
  };

  handleSave = (values) => {

    this.setState({
      isSaving: true,
    });

    let interviewId = getUrlParam('iid');
    if (interviewId === 'new') {
      interviewId = new Date().getTime();
    }

    this.props.firebase.onAuthUserListener(authUser => {
      delete values.isEditing
      delete values.isSaving
      delete values.isLoading
      values.interviewId = interviewId

      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
        .set(values, val => {
          this.setState({...values, isEditing: false, interviewId });
        });
        this.props.history.replace(`/interview?iid=${interviewId}`)
    });
  };

  componentDidMount() {
    const interviewId = getUrlParam('iid');
    if (interviewId !== 'new') {
      this.props.firebase.onAuthUserListener(authUser => {
        database()
          .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
          .once('value')
          .then(snapshot => {
            if (snapshot.val()) {
              this.setState({
                ...snapshot.val() ||  {},
                isLoading: false,
                isNew: false
              });
            }
          });
      });
    } else {
      this.setState({
        isLoading: false,
        isEditing: true,
        isNew: true
      });
    }
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };

  handleDelete = () => {
    const interviewId = getUrlParam('iid');
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
        .set({}, val => {
          this.props.history.replace(`/interviews`)
        });
       
    });
  }

  render() {
    const { isEditing, isLoading, interviewId } = this.state;

    return (
      <Card loading={isLoading}>
        <Row gutter={16}>
          <Col md={16}>
            <InterviewMainDetailsEdit 
                handleSave={this.handleSave} 
                handleCancel={this.handleCancel}
                values={this.state}
            />
            <InterviewMainDetailsView 
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
                values={this.state}
            />
        </Col>
        <Col md={8}>
          {  interviewId !== 'new' && <RoundsGrid interviewId={interviewId}/>}
        </Col>
        </Row>
      </Card>
    );
  }
}
export default withFirebase(InterviewMainDetails);
