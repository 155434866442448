import React, { Component } from 'react'

import { Steps, Button, message, Modal } from 'antd';
import PersonalDetailsIndex from '../PersonalDetails/PersonalDetailsIndex';
import VisaIndex from '../Visa/VisaIndex';
import PersonalDetailsEdit from '../PersonalDetails/PersonalDetailsEdit';
import EmployerDetails from '../../EmployerDetails';
import ResumeUploadIndex from '../ResumeUpload/ResumeUploadIndex';

const { Step } = Steps;

const steps = [
  {
    title: 'First',
    content: <PersonalDetailsIndex />,
  },
  {
    title: 'Second',
    content: <VisaIndex/>,
  },
  {
    title: 'Last',
    content: <ResumeUploadIndex />,
  },
  {
    title: 'Last',
    content: <EmployerDetails />,
  },
];

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    return (
      <div>
          <Modal visible={true} width="80%" footer={null}>
          HELLO
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>
        </Modal>
      </div>
    );
  }
}
