import React from 'react';
import {
    Form,
    Icon,
    Input,
    Row,
    Col,
    Card,
    Tooltip,
    Button,
    Select,
    Radio,
    TimePicker,
  } from 'antd';
import moment from 'moment';

const FieldValue = ({ 
    value, 
    type="text",
    id, 
    name, 
    onChange,
    onBlur,
    label="label",
    textArea,
    rows = 1,
    placeholder = ''
    }) => {
    
  switch(type){
    case "time":
      value = value ? moment.unix(value).format('hh:mm A'): '';
      break;
    case "date":
      value = value ? moment(value).format('MMM DD YYYY') : '';
      break;
  }

  return (
    <Row style={{ fontSize: '1.5em', color: '#5d5858', fontWeight: 'bold'}}>
      <div style={{whiteSpace:'pre-wrap', whiteSpace: 'pre-line'}}> {value || '-'} </div>
    </Row>
  );
};

export default FieldValue
