import React from 'react';
import { Field } from 'formik';
import {
    Form,
    Icon,
    Input,
    Row,
    Col,
    Card,
    Tooltip,
    Button,
    Select,
    Radio,
    TimePicker,
  } from 'antd';

const FieldName = ({ 
    value, 
    id, 
    name, 
    onChange,
    onBlur,
    label="label",
    textArea,
    rows = 1,
    type="text",
    placeholder = ''
    }) => {
  return (
    <Row style={{   
      fontWeight: 'bold',
      fontSize: '1em',
      color: '#ffa315'}}>
      <span  className="form__label">{label}</span>
    </Row>
  );
};

export default FieldName
