import React from 'react';
// import { Formik, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import { database } from 'firebase';
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Spin,
} from 'antd';
import { withFirebase } from './components/Firebase';
// import { AuthUserContext } from './components/Session';
// import { Link } from 'react-router-dom';
//const iFrame = `<iframe src="https://aksharnow.youcanbook.me/?noframe=true&skipHeaderFooter=true" id="ycbmiframeaksharnow" style="width:100%;height:1000px;border:0px;background-color:transparent;" frameborder="0" allowtransparency="true"></iframe><script>window.addEventListener && window.addEventListener("message", function(event){if (event.origin === "https://aksharnow.youcanbook.me"){document.getElementById("ycbmiframeaksharnow").style.height = event.data + "px";}}, false);</script>`

class Resume extends React.Component {
  state = {
    isIframeLoaded: false,
  };

  // componentDidMount() {
  //   this.props.firebase.onAuthUserListener(authUser => {
  //     database()
  //       .ref('users/' + authUser.uid + '/resume/')
  //       .once('value')
  //       .then(snapshot => {
  //         if (snapshot.val()) {
  //           var userExperience = snapshot.val();
  //           this.setState(userExperience);
  //         }
  //       });
  //   });
  // }

  onLoadOfIframe = () => {
    this.setState({
      isIframeLoaded: true,
    });
  };

  // saveToFirebase = authUser => {
  //   const values = this.state;
  //   const userId = authUser.uid;
  //   database()
  //     .ref('users/' + userId + '/resume/')
  //     .set(values);
  // };

  render() {
    const { isIframeLoaded } = this.state;

    // return (
    //   <iframe
    //     ref="iframe"
    //     src="https://aksharnow.youcanbook.me/?noframe=true&skipHeaderFooter=true"
    //     onLoad={() => this.onLoadOfIframe()}
    //     id="ycbmiframeaksharnow"
    //     style={{
    //       width: '100%',
    //       height: '100vh',
    //       overflow: 'no-scroll',
    //     }}
    //     // style="width:100%;height:1000px;border:0px;background-color:transparent;"
    //     frameborder="0"
    //     allowtransparency="true"
    //   />
    // );
    return (
      <>
        {!this.state.isIframeLoaded && <Spin
          spinning={!this.state.isIframeLoaded}
          size="large"
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
    }
        <iframe
          ref="iframe"
          src="https://aksharnow.youcanbook.me/?noframe=true&skipHeaderFooter=true"
          onLoad={() => this.onLoadOfIframe()}
          id="ycbmiframeaksharnow"
          style={{
            width: '100%',
            height: '100vh',
            overflow: 'no-scroll',
          }}
          // style="width:100%;height:1000px;border:0px;background-color:transparent;"
          //frameborder="0"
          allowtransparency="true"
        />
      </>
    );
  }
}
export default withFirebase(Resume);
