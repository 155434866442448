import React from 'react';
import _ from 'lodash';
import RoundsGrid from '../RoundsGrid';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import PostInterviewEdit from './PostInterviewEdit';
import PostInterviewView from './PostInterviewView';

import { Card, Col, Row, Button } from 'antd';
import getUrlParam from '../../utilities/getUrlParams';

class PostInterviewIndex extends React.Component {
  state = {
    postInterviewRating:"",
    postQuestionsAsked:"",
    postNotes:"",

    isEditing: false,
    isLoading: true,
    isSaving: false,
    isNew: false,
    roundId: 'new'
  };

  handleSave = (values) => {

    this.setState({
      isSaving: true,
    });

    let roundId = getUrlParam('rid');
    let interviewId = getUrlParam('iid');

    if (roundId === 'new') {
      roundId = new Date().getTime();
    }

    this.props.firebase.onAuthUserListener(authUser => {
      delete values.isEditing
      delete values.isSaving
      delete values.isLoading
      delete values.isNew
      delete values.interview
      values.roundId = roundId
      values.interviewId = interviewId

      database()
        .ref('users/' + authUser.uid + `/interviews/${interviewId}/rounds/${roundId}/post`)
        .set(values, () => {
          this.setState({...values, isEditing: false, roundId });
        });
        // this.props.history.replace(`/round?iid=${interviewId}&rid=${roundId}`)
    });
  };

  componentDidMount() {
    const interviewId = getUrlParam('iid');
    const roundId = getUrlParam('rid');
    if (roundId !== 'new') {
      this.props.firebase.onAuthUserListener(authUser => {
        database()
          .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
          .once('value')
          .then(snapshot => {
            if (snapshot.val()) {
              this.setState({
                ...snapshot.val().rounds[roundId].post ||  {},
                interview: snapshot.val() || {},
                isLoading: false,
                isNew: false
              });
            }
          });
      });
    } else {
      this.setState({
        isLoading: false,
        //isEditing: true,
        isNew: true
      });
    }
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };


  render() {
    const { isEditing, isLoading } = this.state;

    return (
      <Card loading={isLoading}>
        {
            isEditing ? 
            <PostInterviewEdit
                handleSave={this.handleSave} 
                handleCancel={this.handleCancel}
                values={this.state}
            /> :
            <PostInterviewView 
                handleEdit={this.handleEdit}
                values={this.state}
            />
        }
      </Card>
    );
  }
}
export default withFirebase(PostInterviewIndex);
