import React from 'react';
// import { database, storage } from 'firebase';
import _ from 'lodash';

import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  Select,
  Radio,
  TimePicker,
} from 'antd';
import { withFirebase } from '../Firebase';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
// import Datepicker from '../Datepicker';
// import Timepicker from '../TimePicker';
// import { DropDownMenu } from 'material-ui';
// import getUrlParam from '../../utilities/getUrlParams';
// import InterviewsGrid from './components/InterviewsGrid';

import PreInterview from '../PreInterview/PreInterviewIndex'
import DuringInterview from '../DuringInterview/DuringInterviewIndex';
import PostInterview from '../PostInterview/PostInterviewIndex';
import getUrlParam from '../../utilities/getUrlParams';
import { database } from 'firebase';

const { Option } = Select;

class RountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interviewClient: '',
      roundNumber: '',

      isLoading: true
    };
  }

  // componentDidMount() {
  //   const roundId = getUrlParam('rid');
  //   const interviewId = getUrlParam('iid');
  //   if (interviewId !== 'new') {
  //     this.props.firebase.onAuthUserListener(authUser => {
  //       database()
  //         .ref('users/' + authUser.uid + `/interviews/${interviewId}/dasd`)
  //         .once('value')
  //         .then(snapshot => {
  //             const interview = snapshot.val()

  //             if(interview){
  //               const { interviewClient = 'not set' } = interview
  //               const { roundNumber = "not set" } = _.get(interview,`rounds[${roundId}].pre`)
  //               debugger
  //               this.setState({
  //                 isLoading: false,
  //                 interviewClient,
  //                 roundNumber
  //               })
  //             }else{
  //               this.setState({
  //                 isLoading: false
  //               })
  //             }


  //         });
  //     });
  //   }
  // }

  // // saveToFirebase = authUser => {
  // //   const values = this.state;
  // //   const userId = authUser.uid;
  // //   database()
  // //     .ref('users/' + userId + '/interviewDetails/')
  // //     .set(values);
  // // };

  // handleAddInterview = () => {
  //   const roundId = getUrlParam('rid');
  //   const interviewId = getUrlParam('iid');
  //   this.props.firebase.onAuthUserListener(authUser => {
  //     database()
  //       .ref('users/' + authUser.uid + `/interviews/${interviewId}/rounds/${roundId}`)
  //       .set(this.state, () => {
  //         this.props.history.push('/interviews');
  //       });
  //   });
  // };

  // handleSaveInterview = () => {
  //   const interviewId = getUrlParam('iid');
  //   this.props.firebase.onAuthUserListener(authUser => {
  //     database()
  //       .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
  //       .set(this.state, () => {
  //         this.props.history.push('/interviews');
  //       });
  //   });
  // };

  // handleDelete = () => {
  //   const interviewId = getUrlParam('iid');
  //   this.props.firebase.onAuthUserListener(authUser => {
  //     database()
  //       .ref('users/' + authUser.uid + `/interviews/${interviewId}`)
  //       .remove()
  //       .then(() => {
  //         this.props.history.push('/interviews');
  //       });
  //   });
  // };

  // handleCancel = () => {
  //   this.props.history.push('/interviews');
  // };

  // handleChange = ({ id: key, value }) => {
  //   const newState = _.set(this.state, key, value);
  //   this.setState(newState);
  // };

  // handleJobTypeChange = e => {
  //   this.setState({
  //     jobType: e.target.value,
  //   });
  // };

  // handleFileChoose = e => {
  //   e.preventDefault();
  //   const file = e.target.files[0];
  //   if (file) {
  //     this.setState({
  //       file,
  //     });
  //   }
  // };

  // handleUploadFiles = e => {
  //   const file = this.state.file;
  //   if (file) {
  //     const interviewId = getUrlParam('iid');
  //     this.props.firebase.onAuthUserListener(authUser => {
  //       var storageRef = storage().ref(
  //         `${authUser.uid}/interviews/${interviewId}/${file.name}+${file.lastModified}`,
  //       );
  //       storageRef.put(file).then(function() {
  //         alert('File Uploaded');
  //       });
  //       e.preventDefault();
  //     });
  //   }
  // };

  render() {
    // const interviewId = getUrlParam('iid');
    // let actionsToRender = null;
    // let headerToRender = null;
    // if (interviewId === 'new') {
    //   actionsToRender = (
    //     <>
    //       <Button onClick={this.handleAddInterview}>Add</Button>
    //       <Button onClick={this.handleCancel}>Cancel</Button>
    //     </>
    //   );
    //   headerToRender = <h3>Adding upcoming interview</h3>;
    // } else {
    //   actionsToRender = (
    //     <>
    //       <Button onClick={this.handleSaveInterview}>Save</Button>
    //       <Button onClick={this.handleDelete}>Delete</Button>
    //     </>
    //   );
    //   headerToRender = <h3>Updating interview</h3>;
    // }
    const { isLoading, interviewClient, roundNumber } = this.state
    return (
      <Card>
        <PreInterview {...this.props}/>
        <DuringInterview {...this.props}/>
        <PostInterview {...this.props}/>
        {/* <DuringInterview interview={this.state} onChange={this.handleChange.bind(this)}/>
        <PostInterview interview={this.state} onChange={this.handleChange.bind(this)}/> */}
      </Card>
    );
  }
}
export default withFirebase(RountDetails);
