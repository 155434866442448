import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import InputMaker from '../Form';
import FieldDisplay from '../FieldDisplay';
import RoundsGrid from '../RoundsGrid';
import Rows from './Rows';
import { database, storage } from 'firebase';
import { withFirebase } from '../Firebase';

import {
  firstName,
  lastName,
  phone,
  email,
  totalExpYears,
  totalExpMonths,
  USTotalExpYears,
  USTotalExpMonths,
  personalSummary
} from '../../constants/fieldIds.json';

import { Card, Col, Row, Button, Breadcrumb, Menu,Typography, Divider, Input   } from 'antd';

const { Title, Paragraph, Text } = Typography;

class ResumeTemplate extends React.Component {

  state = {
    isPublic: false,
    isPublished: false,
    publicResumeId: "",
    resume:  "",
    isLoading: true,
  }

  componentDidMount() {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/publish')
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            this.setState({
              ...snapshot.val(),
              isLoading: false
            });
          }else{
            this.setState({
              isLoading: false
            })
          }
        });
    });
  }

  handlePublish = () => {
    this.props.firebase.onAuthUserListener(authUser => {

      const { publicResumeId, isPublic, isPublished, resume } = this.state
      database()
        .ref('public/' + publicResumeId)
        .once('value')
        .then(snapshot => {
          var userProfile = snapshot.val()
          if (userProfile) {
            if(userProfile.userId === authUser.uid){
              database()
              .ref('public/' + publicResumeId.toLowerCase())
              .set({
                publicResumeId,
                userId: authUser.uid,
                isPublic: true,
                isPublished: true, 
                resume
              }, ()=>{
                this.setState({
                  isPublic: true,
                  isPublished: true
                })
                  alert('Success')
              }) 
            }else{
              alert("User Name Already Taken")
            }
          }else{
            database()
              .ref('public/' + publicResumeId)
              .set({
                userId: authUser.uid,
                publicResumeId,
                isPublic: true,
                isPublished: true, 
                resume: this.state.resume
              }, ()=>{
                this.setState({
                  isPublic: true,
                  isPublished: true,
                })
                alert('Super, You resume is live at https://', publicResumeId, '.com')
              })


              database()
              .ref('users/' + authUser.uid + '/publish')
              .set({
                publicResumeId,
                isPublic: true,
                isPublished: true, 
                resume: this.state.resume
              })

            // alert('You already have resume published at ', this.state.publicResumeId )
          }
        });
    


      });
  }

  handleSave = () => {
    this.props.firebase.onAuthUserListener(authUser => {
        database()
          .ref('users/' + authUser.uid + '/publish')
          .update({
            resume: this.state.resume
          }, ()=>{
              alert('Success')
          })
      });
  }

  handleMakeItPublic = () => {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/publish/isPublic')
        .set(true, ()=>{
          database()
          .ref('public/' + this.state.publicResumeId + '/isPublic')
          .set(true, ()=>{
              alert('Your resume is  public')
          })
        })

        this.setState({
          isPublic: true,
          isPublished: true
        })

    });
  }

  handleUnPublish = () => {
    this.props.firebase.onAuthUserListener(authUser => {
      database()
        .ref('users/' + authUser.uid + '/publish')
        .update({
          isPublic: false,
          isPublished: false,
        }, ()=>{
          
          database()
          .ref('public/' + this.state.publicResumeId)
          .set({}, ()=>{
            alert('Your resume is not public any more')
            this.setState({
              isPublic: false,
              isPublished: false,
            })
          })
        })
    });
  }

  renderHeader = () => {
    const { isPublic, isPublished, publicResumeId } = this.state
    if(isPublic && isPublished){
      return(
        <div>
          <Title level={3}>Your Resume is Currently Published at URL: <a href={`/r/${publicResumeId}`} target="_blank">{`https://linterviews/r/${publicResumeId}`}</a></Title>
          <Button onClick={this.handleUnPublish}>Un Publish</Button>
        </div>
      )
    }

    if(!isPublished){
      return(
        <div>
          <Row gutter={16}>
            <Col md={24}>
              <Title level={3}>Create a personalized Unique URL below to publish your resume to share with others:</Title>
            </Col>
          </Row> 
          <Row gutter={16}>
          <Col md={6}>
              <Input value={publicResumeId} onChange={(e)=>{this.setState({publicResumeId: e.target.value.toLowerCase().replace(/\s/g,'-')})}} />
              <Text underline>{`www.linterviews.com/r/${publicResumeId}`}</Text>
            </Col>
            <Col md={12}>
              <Button disabled={!publicResumeId} onClick={this.handlePublish}> Publish </Button>
            </Col>
          </Row>
        </div>
      )
    }

    if(isPublished && !isPublic){
      return(
        <div>
          <Title>
          Your Resume is Published but not visible to public
          </Title>
          <Button onClick={this.handleMakeItPublic}>Make it Public</Button>
          <div>
          Your Personalized URL is: <a href={`/r/${publicResumeId}`} target="_blank">{`www.linterviews.com/r/${publicResumeId}`}</a>
          </div>
        </div>
      )
    }
    
    return null

  }

  render() {
    return (
      <Card className="add-shadow" loading={this.state.isLoading}>
        <Typography>
            {
              this.renderHeader()
            }
            {/* <div>
              Public URL: 
              <input value={this.state.publicResumeId} onChange={(e)=>{this.setState({publicResumeId: e.target.value})}} />
              <Button onClick={this.handlePublish}> Publish </Button>
            </div> */}
            <br />

            <Row>
              <Title> My Resume: </Title>
            </Row>
            <Row gutter={16} style={{margin: '10px 0px'}}>
              <Input.TextArea rows={20} value={this.state.resume} onChange={(e)=>{this.setState({resume: e.target.value })}} />
            </Row>
            <Row gutter={16}>
              <Col md={3}>
                <Button onClick={this.handleSave}> Save for later </Button>
              </Col>
              <Col md={2}>
                <Button onClick={this.handlePublish}> Publish Now</Button>
              </Col>
            </Row>

            <div style={{whiteSpace:'pre-wrap', whiteSpace: 'pre-line'}}>{this.state.resume}</div>
            </Typography>
      </Card>
    );
  }
}
export default withFirebase(ResumeTemplate);